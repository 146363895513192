"use client";
import { createContext, useReducer } from "react";

export const PlayerContext = createContext();
export const PlayerReducer = (state, action) => {
	switch (action.type) {
		case "PLAYERTRUE":
			return { player: action.payload };
		case "PLAYERFALSE":
			return { player: null };
		default:
			return state;
	}
};
export const PlayerContextProvider = ({ children }) => {
	const [state, dispatch4] = useReducer(PlayerReducer, {
		player: null,
	});
	return (
		<PlayerContext.Provider value={{ ...state, dispatch4 }}>
			{children}
		</PlayerContext.Provider>
	);
};
