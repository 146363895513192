// importing the bug report left image stylings
import "../styles/BugReportScreen.css";

// importing the report the bug image from assets
import reportBug from "../../../../assets/ReportBugs.png";

// declaring bug report image function for showing image
export default function BugReportLeftImg() {
  return (
    <div className="BugReportLeftImgWelcome">
      {/* added the division for giving width and the padding */}
      <img src={reportBug} alt="reportBug" />
    </div>
  );
}
