import React, { useState, useEffect } from "react";

// importing the course screen styles for stylings
import "./../styles/CoursesScreen.css";

// importing course cards components for showing courses
import CoursesCards from "./components/CoursesCards";

// importing course thumbnail for showing inside course dashboard
// import redBookWebCover from "../../../../assets/RedBookWebCover.jpg";
import homeContainerConstructionImage from "../../../../assets/HomeContainerConstructionImage.png";

// importing javascript cookies for setting and getting global cookies
import Cookies from "js-cookie";

// importing the jwt decode for decoding the tokens details
import jwt_decode from "jwt-decode";

// importing the courses list from course services
import { coursesServices } from "../../../../services/courses/CoursesServices";

// importing the courses array service for showing specific courses into students
import { getCoursesArrayService } from "../../../../services/courses/GetCoursesArrayService";

import { getSubCoursesService } from "../../../../services/courses/GetSubCoursesService";
import { getCompletedLessonIDs } from "../../../../services/dripFeeding/GetCompletedLessonIDs";
import { GetLessonDetailsWithSubCourseId } from "../../../../services/lessons/GetLessonDetailsWithSubCourseId";
import { GetLessonDetailsWithCourseId } from "../../../../services/lessons/GetLessonDetailsWithCourseId";

export default function CoursesIndexScreen() {
	// used progress and completed variable for toggling buttons
	const [progress, setProgress] = useState(true);
	const [completed, setCompleted] = useState(false);

	// declaring token variable for getting global authToken
	var token = Cookies.get("authToken");
	var user = jwt_decode(token);
	// used response for getting response from courseservice
	var [response, setResponse] = useState([]);
	function removeDuplicates(arr) {
		return [...new Set(arr)];
	}
	var [percentageArray, setPercentageArray] = useState([]);
	var [percentageIArray, setPercentageIArray] = useState([]);
	var [completedLessonArray, setCompletedLessonArray] = useState([]);
	var [totalLessonArray, setTotalLessonArray] = useState([]);
	useEffect(() => {
		// calling the course service for getting course list
		coursesServices().then((result) => {
			var rewampedCoursesArray = [];
			getCoursesArrayService(user.id).then((resultArray) => {
				// eslint-disable-next-line array-callback-return
				result.map((responses) => {
					if (resultArray.includes(responses.id)) {
						rewampedCoursesArray.push(responses);
					}
				});
				setResponse(rewampedCoursesArray);
				// below variable for storing percentages and indexes
				var thePercentageArray = [];
				var percentageIndexArray = [];
				var completedLessonsArray = [];
				var totalLessonsArray = [];
				// below statement maps the rewamped course array
				var promises = rewampedCoursesArray.map(
					(progressResponse, index) => {
						return new Promise((resolve) => {
							// below condition was rendered when course has subcourses
							if (progressResponse.isSubCourse) {
								getSubCoursesService(progressResponse.id).then(
									(subcourseDetails) => {
										// below variables stores completed and total video array
										var dripFeedingArray = [];
										var totalLessonsCount = [];

										Promise.all(
											// below statement traverses the whole subcourses
											subcourseDetails.map(
												async (
													mapedSubcourseDetails
												) => {
													var dripResult =
														await getCompletedLessonIDs(
															{
																studentId:
																	user.id,
																courseId: 0,
																subCourseId:
																	mapedSubcourseDetails.id,
															}
														);
													var lessonResult =
														await GetLessonDetailsWithSubCourseId(
															mapedSubcourseDetails.id
														);
													// below commands updates completed and total video array
													dripFeedingArray.push(
														removeDuplicates(
															dripResult
														)
													);
													totalLessonsCount.push(
														lessonResult.length
													);
												}
											)
										).then(() => {
											var concatenatedArray = [].concat(
												...dripFeedingArray
											);
											var lessonConcatenatedArray =
												[].concat(...totalLessonsCount);
											var sum =
												lessonConcatenatedArray.reduce(
													(a, b) => a + b
												);
											// console.log(Math.ceil(percentage));
											// below command updates the percentages and indexes
											percentageIndexArray.push(index);
											if (
												progressResponse.id === 11 ||
												progressResponse.id === 12
											) {
												// unblocking the 6videos for the ff1 course
												completedLessonsArray.push(
													concatenatedArray.length + 6
												);
												thePercentageArray.push(
													Math.ceil(
														((concatenatedArray.length +
															6) *
															100) /
															sum
													)
												);
											} else {
												completedLessonsArray.push(
													concatenatedArray.length + 2
												);
												thePercentageArray.push(
													Math.ceil(
														((concatenatedArray.length +
															2) *
															100) /
															sum
													)
												);
											}
											totalLessonsArray.push(sum);
											resolve();
										});
									}
								);
								// below condition was rendered when course doesnot has subcourses
							} else if (!progressResponse.isSubCourse) {
								// below two services helps updating percentages and indexes
								getCompletedLessonIDs({
									studentId: user.id,
									courseId: progressResponse.id,
									subCourseId: 0,
								}).then((dripResult) => {
									var completedArray =
										(removeDuplicates(dripResult).length +
											2) *
										100;
									GetLessonDetailsWithCourseId(
										progressResponse.id
									).then((result) => {
										// console.log(Math.ceil(completedArray / result.length));
										percentageIndexArray.push(index);
										if (progressResponse.id === 2) {
											// unblocking the 3videos for the jgm course
											completedLessonsArray.push(
												removeDuplicates(dripResult)
													.length + 3
											);
											thePercentageArray.push(
												Math.ceil(
													((removeDuplicates(
														dripResult
													).length +
														3) *
														100) /
														result.length
												)
											);
										} else if (progressResponse.id === 34) {
											// unblocking the 5videos for come india sing
											completedLessonsArray.push(
												removeDuplicates(dripResult)
													.length + 5
											);
											thePercentageArray.push(
												Math.ceil(
													((removeDuplicates(
														dripResult
													).length +
														5) *
														100) /
														result.length
												)
											);
										} else if (progressResponse.id === 10) {
											completedLessonsArray.push(
												removeDuplicates(dripResult)
													.length
											);
											thePercentageArray.push(
												Math.ceil(
													(removeDuplicates(
														dripResult
													).length *
														100) /
														result.length
												)
											);
										} else {
											thePercentageArray.push(
												Math.ceil(
													completedArray /
														result.length
												)
											);
											completedLessonsArray.push(
												completedArray / 100
											);
										}
										totalLessonsArray.push(result.length);
										resolve();
									});
								});
							}
						});
					}
				);
				Promise.all(promises).then(() => {
					setPercentageArray(thePercentageArray);
					setPercentageIArray(percentageIndexArray);
					setCompletedLessonArray(completedLessonsArray);
					setTotalLessonArray(totalLessonsArray);
				});
			});
		});
	}, [user.id]);
	return (
		<>
			{user.coursesAllowed ? (
				<div className='HomeContextContainer'>
					<h2 className='CoursesScreenHeading'>Enrolled Courses</h2>
					{/* making statusbuttons for viewing completed and inprogress courses */}
					<div className='CoursesScreenStatusButtons'>
						<button
							className={
								progress === true
									? "Switched"
									: "CoursesScreenButton"
							}
							onClick={() => {
								setProgress(true);
								setCompleted(false);
							}}>
							In Progress
						</button>
						<button
							className={
								completed === true
									? "Switched"
									: "CoursesScreenButton"
							}
							onClick={() => {
								setProgress(false);
								setCompleted(true);
							}}>
							Completed
						</button>
					</div>
					{percentageIArray.length === 0 ? (
						<div className='wrapper'>
							<span className='circle circle-1'></span>
							<span className='circle circle-2'></span>
							<span className='circle circle-3'></span>
							<span className='circle circle-4'></span>
							<span className='circle circle-5'></span>
							<span className='circle circle-6'></span>
							<span className='circle circle-7'></span>
							<span className='circle circle-8'></span>
						</div>
					) : null}
					<div className='CoursesScreenCoursesList'>
						{percentageIArray.length === 0 || completed
							? null
							: response.map((responses, index) => {
									var percentages =
										percentageArray[
											percentageIArray.indexOf(index)
										];
									var completedLessons =
										completedLessonArray[
											percentageIArray.indexOf(index)
										];
									var totalLessons =
										totalLessonArray[
											percentageIArray.indexOf(index)
										];
									return (
										<CoursesCards
											key={responses.id}
											serialNumber={responses.id}
											title={responses.courseTitle}
											courseDescription={
												responses.courseDescription
											}
											thumbnail={responses.imgURL}
											isSubCourse={responses.isSubCourse}
											percentages={percentages}
											completedLessons={completedLessons}
											totalLessons={totalLessons}
										/>
									);
							  })}
					</div>
				</div>
			) : (
				<img
					src={homeContainerConstructionImage}
					alt='homeContainerConstructionImage'
					className='HomeContainerConstructionImage'
				/>
			)}
		</>
	);
}
