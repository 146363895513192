// importing use state for updating rerendered variables
import { useState, useEffect } from "react";
import { CONFIG } from "../../../../config";
// importing the global styles for aquiring global css
import "../styles/GlobalProfile.css";

// importing profile manage styles for screen stylings
import "./styles/ProfileManage.css";

// importing javascript cookies for setting and getting global cookies
import Cookies from "js-cookie";
import { useMessageContext } from "../../../../hooks/UseMessageContext";

// importing the user details service for getting the user details
import { userDetailsService } from "../../../../services/userDetails/UserDetailsServices";
import { PostUserProfileDetails } from "../../../../services/profile/PostUserProfileDetails";
import { GetUserProfileDetails } from "../../../../services/profile/GetUserProfileDetails";
import { UpdateUserDescription } from "../../../../services/profile/UpdateUserDescription";
import { updateUserDirectPhoto } from "../../../../services/profile/UpdateUserDirectPhoto";

// importing the logos and the social icons from the assets
import userProfileLogo from "../../../../assets/UserProfileLogo.png";
import profileFacebookIcon from "../../../../assets/profileIcons/FacebookIcon.svg";
import profileInstagramIcon from "../../../../assets/profileIcons/InstagramIcon.svg";
import profileTwitterIcon from "../../../../assets/profileIcons/TwitterIcon.svg";
import profileYoutubeIcon from "../../../../assets/profileIcons/YoutubeIcon.svg";
import profileLinkedinIcon from "../../../../assets/profileIcons/LinkedinIcon.svg";

export default function ProfileManage() {
	const { dispatch2 } = useMessageContext();
	const { messageArray } = useMessageContext();
	// these variables are input values and are updated on rerendered
	const [profileFacebookLink, setProfileFacebookLink] = useState("");
	const [profileInstagramLink, setProfileInstagramLink] = useState("");
	const [profileTwitterLink, setProfileTwitterLink] = useState("");
	const [profileYoutubeLink, setProfileYoutubeLink] = useState("");
	const [profileLinkedinLink, setProfileLinkedinLink] = useState("");
	const [userDescription, setUserDescription] = useState("");
	const [userProfileDirectPhoto, setUserProfileDirectPhoto] = useState(null);

	// declaring the below variables for showing the user details
	const [userAttachedPhoto, setUserAttachedPhoto] = useState(null);
	const [userFirstName, setUserFirstName] = useState("");
	const [userLastName, setUserLastName] = useState("");
	const [userDOB, setUserDOB] = useState("");
	const [UserMobile, setUserMobile] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [userId, setUserId] = useState("");

	// declaring the below variable for giving the socialurls editing access
	const [socialURLEditingAccess, setSocialURLEditingAccess] = useState(false);
	// declaring the below variable for giving the description editing access
	const [descriptionEditingAccess, setDescriptionURLEditingAccess] =
		useState(false);

	useEffect(() => {
		// declaring token variable for getting global authToken
		const token = Cookies.get("authToken");

		// adding imported user details service for showing userdetails
		userDetailsService(token)
			.then((result) => {
				setUserFirstName(result.firstName);
				setUserLastName(result.lastName);
				setUserDOB(result.dob);
				setUserMobile(result.phoneNo);
				setUserEmail(result.email);
				setUserId(result.id);
				GetUserProfileDetails(result.id).then((results) => {
					setUserProfileDirectPhoto(results.dpurl);
					setUserDescription(results.bio);
					setProfileInstagramLink(results.instagram);
					setProfileFacebookLink(results.facebook);
					setProfileTwitterLink(results.x);
					setProfileYoutubeLink(results.youtube);
					setProfileLinkedinLink(results.linkedIn);
				});
			})
			.catch((e) => console.log(e));
	}, []);
	const postUserProfileDetails = async () => {
		const formData = new FormData();
		formData.append("x", profileTwitterLink);
		formData.append("facebook", profileFacebookLink);
		formData.append("instagram", profileInstagramLink);
		formData.append("youtube", profileYoutubeLink);
		formData.append("linkedIn", profileLinkedinLink);
		const respond = await PostUserProfileDetails(formData, userId);
		if (respond === "updated the student details into the database") {
			dispatch2({
				type: "MESSAGEARRAY",
				payload: [
					...messageArray,
					"Success: Successfully Updated the URLs",
				],
			});
			setSocialURLEditingAccess(false);
		}
	};
	const updateUserDescription = async () => {
		const formData = new FormData();
		formData.append("bio", userDescription);
		const respond = await UpdateUserDescription(formData, userId);
		if (respond === "updated the student details into the database") {
			dispatch2({
				type: "MESSAGEARRAY",
				payload: [
					...messageArray,
					"Success: Successfully Updated the BIO",
				],
			});
			setDescriptionURLEditingAccess(false);
		}
	};
	const updateUserDirectPhotos = async () => {
		const formData = new FormData();
		formData.append("pdfFile", userAttachedPhoto);
		const respond = await updateUserDirectPhoto(formData, userId);
		if (respond === "updated the student details into the database") {
			dispatch2({
				type: "MESSAGEARRAY",
				payload: [
					...messageArray,
					"Success: Successfully Updated your DP",
				],
			});
			setUserAttachedPhoto(null);
		}
	};
	return (
		<div className='HomeContextContainer'>
			<div className='GlobalProfileDetails'>
				<div className='GlobalProfileDetailsList'>
					<img
						src={
							userProfileDirectPhoto === "NULL" ||
							userProfileDirectPhoto === null
								? userProfileLogo
								: CONFIG.STUDENTURL + userProfileDirectPhoto
						}
						alt='userProfileLogo'
					/>
					<button className='ProfileManageAttachButton'>
						{userAttachedPhoto === null
							? "Attach your Photo"
							: userAttachedPhoto.name}
						<input
							type='file'
							accept='image/*'
							onChange={(event) =>
								setUserAttachedPhoto(event.target.files[0])
							}
						/>
					</button>
					{userAttachedPhoto ? (
						<button onClick={() => updateUserDirectPhotos()}>
							Save your Photo
						</button>
					) : null}
					<input
						autoComplete='password'
						placeholder={userFirstName + " " + userLastName}
						disabled='true'
					/>
					<input
						autoComplete='password'
						placeholder={userDOB === null ? "Birth Date" : userDOB}
						disabled='true'
					/>
					<input
						autoComplete='password'
						placeholder={
							UserMobile === null ? "Phone Number" : UserMobile
						}
						disabled='true'
					/>
					<input
						autoComplete='password'
						placeholder={
							userEmail === null ? "Email Address" : userEmail
						}
						disabled='true'
					/>
				</div>
			</div>
			{/* profile manage description wrapper wraps two similar boxes */}
			<div className='ProfileManageDescriptionWrapper'>
				{/* profile manage description wraps the profile description */}
				<div className='ProfileManageDescription'>
					<div className='ProfileManageDescriptionHeadingBioWrapper'>
						<h3>My profile</h3>
						{descriptionEditingAccess ? (
							<button onClick={() => updateUserDescription()}>
								Save
							</button>
						) : (
							<button
								onClick={() =>
									setDescriptionURLEditingAccess(true)
								}>
								Edit
							</button>
						)}
					</div>
					<textarea
						value={userDescription}
						autoComplete='password'
						disabled={!descriptionEditingAccess}
						onChange={(event) =>
							setUserDescription(event.target.value)
						}
					/>
				</div>
				{/* this profile manage description wraps the social links */}
				<div className='ProfileManageDescription'>
					<div className='ProfileManageDescriptionHeadingBioWrapper'>
						<h3>Social profile</h3>
						{socialURLEditingAccess ? (
							<button onClick={() => postUserProfileDetails()}>
								Save
							</button>
						) : (
							<button
								onClick={() => setSocialURLEditingAccess(true)}>
								Edit
							</button>
						)}
					</div>
					<ul>
						<li>
							<img
								src={profileFacebookIcon}
								alt='profileFacebookIcon'
							/>
							<input
								autoComplete='password'
								value={profileFacebookLink}
								disabled={!socialURLEditingAccess}
								placeholder='Enter Your Facebook Link'
								onChange={(e) =>
									setProfileFacebookLink(e.target.value)
								}
							/>
						</li>
						<li>
							<img
								src={profileInstagramIcon}
								alt='profileInstagramIcon'
							/>
							<input
								autoComplete='password'
								value={profileInstagramLink}
								disabled={!socialURLEditingAccess}
								placeholder='Enter Your Instagram Link'
								onChange={(e) =>
									setProfileInstagramLink(e.target.value)
								}
							/>
						</li>
						<li>
							<img
								src={profileTwitterIcon}
								alt='profileTwitterIcon'
							/>
							<input
								autoComplete='password'
								value={profileTwitterLink}
								disabled={!socialURLEditingAccess}
								placeholder='Enter Your twitter Link'
								onChange={(e) =>
									setProfileTwitterLink(e.target.value)
								}
							/>
						</li>
						<li>
							<img
								src={profileYoutubeIcon}
								alt='profileYoutubeIcon'
							/>
							<input
								autoComplete='password'
								value={profileYoutubeLink}
								disabled={!socialURLEditingAccess}
								placeholder='Enter Your Youtube Link'
								onChange={(e) =>
									setProfileYoutubeLink(e.target.value)
								}
							/>
						</li>
						<li>
							<img
								src={profileLinkedinIcon}
								alt='profileYoutubeIcon'
							/>
							<input
								autoComplete='password'
								value={profileLinkedinLink}
								disabled={!socialURLEditingAccess}
								placeholder='Enter Your Linkedin Link'
								onChange={(e) =>
									setProfileLinkedinLink(e.target.value)
								}
							/>
						</li>
					</ul>
				</div>
			</div>
			{/* <button className="ZoomMeetingButton">Save</button> */}
		</div>
	);
}
