import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import "../styles/QuizPopup.css";
// import sideCloseIcon from "../../../../assets/SideCloseIcon.svg";
// import { usePlayerContext } from "../../../../hooks/UsePlayerContext";
import { lessonsURLServices } from "../../../../services/lessons/lessonsURLServices";

export default function LessonPlayerPopup(props) {
	// const { dispatch4 } = usePlayerContext();
	const [response, setResponse] = useState("");
	useEffect(() => {
		lessonsURLServices(props.videosName).then(setResponse);
	}, [props.videosName]);
	return (
		<ReactPlayer
			config={{ file: { attributes: { controlsList: "nodownload" } } }} // disables download button
			onContextMenu={(e) => e.preventDefault()} // disables right click
			url={response}
			controls
			width='50%'
			height='auto'
		/>
		// use below code for optimizing popup
		// <div className='QuizPopupWrapper'>
		// 	<div className='QuizPopupHeader'>
		// 		<h3>Video</h3>
		// 		<img
		// 			src={sideCloseIcon}
		// 			alt='sideCloseIcon'
		// 			onClick={function () {
		// 				dispatch4({ type: "PLAYERFALSE" });
		// 			}}
		// 		/>
		// 	</div>
		// 	<div>
		// 		<div className='PlayerStylesheet'>
		// 			<ReactPlayer
		// 				config={{
		// 					file: {
		// 						attributes: { controlsList: "nodownload" },
		// 					},
		// 				}} // disables download button
		// 				onContextMenu={(e) => e.preventDefault()} // disables right click
		// 				url={response}
		// 				controls
		// 				width='50%'
		// 				height='auto'
		// 			/>
		// 		</div>
		// 	</div>
		// </div>
	);
}
