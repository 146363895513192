import { useState, useEffect } from "react";
// importing audio evaluation stylings for screen styles
import "../styles/GlobalEvaluation.css";
import { CONFIG } from "../../../../config";
// importing the cookies library
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import axios from "axios";

// importing back space arrow from assets for showing back arrow
import backspaceArrow from "../../../../assets/BackspaceArrow.svg";

// importing the lesson card thumbnails for showing lesson thumbnails
// import lessonsCardsThumbnails from "../../../../assets/LessonsCardsThumbnails.webp";

// importing the result card for showing the evaluation results
import EvaluationResultCard from "../components/EvaluationResultCard";
import { useMessageContext } from "../../../../hooks/UseMessageContext";

// importing link from react router for routing between screens
import { Link, useLocation } from "react-router-dom";
import MicRecorder from "mic-recorder-to-mp3";
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export default function RealtimeEvaluation(props) {
	const { dispatch2 } = useMessageContext();
	const { messageArray } = useMessageContext();
	const location = useLocation();
	const token = Cookies.get("authToken");
	const decode = jwt_decode(token);
	// const coursesId = Cookies.get("coursesId");
	const [clickedStart, setClickedStart] = useState(false);
	const [clickedStop, setClickedStop] = useState(false);
	const [clickedEvaluate, setClickedEvaluate] = useState(false);
	const [response, setResponse] = useState(null);
	const [loading, setLoading] = useState(false);
	const [isRecording, setIsRecording] = useState(false);
	const [blobURL, setBlobURL] = useState("");
	const [isBlocked, setIsBlocked] = useState(false);
	const start = () => {
		dispatch2({
			type: "MESSAGEARRAY",
			payload: [...messageArray, "Successfully started your recording"],
		});
		setClickedStart(true);
		setClickedStop(false);
		setClickedEvaluate(false);
		if (isBlocked) {
			console.log("permission denied");
		} else {
			Mp3Recorder.start()
				.then(() => {
					setIsRecording(true);
				})
				.catch((e) => console.error(e));
		}
	};
	// below function stops the recorder
	const stop = async () => {
		dispatch2({
			type: "MESSAGEARRAY",
			payload: [...messageArray, "Successfully stopped your recording"],
		});
		setClickedStart(false);
		setClickedStop(true);
		setClickedEvaluate(false);
		Mp3Recorder.stop()
			.getMp3()
			.then(([buffer, blob]) => {
				setBlobURL(blob);
				setIsRecording(false);
				// uncomment for saving the recorded audio
				// const url = URL.createObjectURL(blob);
				// const a = document.createElement("a");
				// a.href = url;
				// a.download = "recorded.wav";
				// a.style.display = "none";
				// document.body.appendChild(a);
				// a.click();
			})
			.catch((e) => console.log(e));
	};

	const upload = async () => {
		dispatch2({
			type: "MESSAGEARRAY",
			payload: [...messageArray, "Please wait, our A.I. doing it's job"],
		});
		setClickedStart(false);
		setClickedStop(false);
		setClickedEvaluate(true);
		setLoading(true);
		const formData = new FormData();
		// console.log(blobURL);
		// console.log(decode.id);
		// console.log(location.state === null ? null : location.state.pieceId);
		formData.append("user-audio-file", blobURL);
		formData.append("user-id", decode.id);
		formData.append(
			"piece-id",
			location.state === null ? null : location.state.pieceId
		);
		// formData.append("course-id", 1);
		axios
			.post(CONFIG.EVALUATIONURL, formData)
			.then((response) => {
				console.log("file uploaded successfully:", response.data);
				setResponse(response.data);
			})
			.catch((error) => {
				console.error("error uploading file:", error);
				setResponse(null);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	useEffect(() => {
		navigator.mediaDevices
			.getUserMedia({ audio: true })
			.then(() => {
				console.log("Permission Granted");
				setIsBlocked(false);
			})
			.catch(() => {
				console.log("Permission Denied");
				setIsBlocked(true);
			});
	}, []);

	return (
		<div className='HomeContextContainer'>
			<div className='GlobalEvaluationWrapper'>
				<div className='GlobalEvaluationButtonHeadWrapper'>
					<div className='GlobalEvaluationHeading'>
						<Link to='/home/lessons'>
							<img src={backspaceArrow} alt='backspaceArrow' />
						</Link>
						<h2>Realtime Audio Evaluation</h2>
					</div>
					<div className='GlobalEvaluationButtons'>
						<button
							className={
								clickedStart
									? "GlobalEvaluationButton2 Clicked"
									: "GlobalEvaluationButton2 NotClicked"
							}
							onClick={start}
							disabled={isRecording}>
							Start
						</button>
						<button
							className={
								clickedStop
									? "GlobalEvaluationButton2 Clicked"
									: "GlobalEvaluationButton2 NotClicked"
							}
							onClick={stop}
							disabled={!isRecording}>
							Stop
						</button>
						<button
							className={
								clickedEvaluate
									? "GlobalEvaluationButton2 Clicked"
									: "GlobalEvaluationButton2 NotClicked"
							}
							onClick={upload}
							disabled={isRecording}>
							Evaluate
						</button>
					</div>
				</div>
				<div className='GlobalEvaluationThumbnailSpace'>
					<img
						src={
							location.state === null
								? null
								: location.state.thumbnail
						}
						alt='lessonsCardsThumbnails'
					/>
				</div>
			</div>
			{loading === true ? (
				<h3>Please wait while we process your Audio</h3>
			) : null}
			{response !== null ? (
				<div id='evaluation-result-wrapper'>
					<EvaluationResultCard
						title='Your score has been Evaluated!'
						notesPlayed={response.extracted_notes}
						actualNotes={response.master_notes}
						accuracy={response.audio_score}
						pieceId={
							location.state === null
								? null
								: location.state.pieceId
						}
						studentID={
							location.state === null
								? null
								: location.state.studentID
						}
						courseLastLessonID={
							location.state === null
								? null
								: location.state.courseLastLessonID
						}
						courseId={
							location.state === null
								? null
								: location.state.courseId
						}
						subCoursesId={
							location.state === null
								? null
								: location.state.subCoursesId
						}
					/>
				</div>
			) : (
				<div className='EvaluationInstructionWrap'>
					<div className='EvaluationInstruction EvaluationResultCardFontSize'>
						<h3 className='ResultHeading'>
							<span className='ResultHeading'>
								Guideline on how to evaluate
							</span>
						</h3>
						<h3 className='ResultBody'>
							<span>1. </span>Click on the{" "}
							<span>Start button</span> and the button will change
							its colour to <span>darker shade of blue</span> and
							your cursor will change into the{" "}
							<span>Red stop sign.</span>
						</h3>
						<h3 className='ResultBody'>
							<span>2. </span>Start playing your{" "}
							<span>Piano / Keyboard.</span>
						</h3>
						<h3 className='ResultBody'>
							<span>3. </span>Click on the{" "}
							<span>stop button</span> and it will turn its colour
							to the <span>darker shade of blue</span> with{" "}
							<span>Red stop sign.</span>
						</h3>
						<h3 className='ResultBody'>
							<span>4. </span>Click on the{" "}
							<span>Evaluate button</span> and wait for our AI
							system to Evaluate you.
						</h3>
						<h3 className='ResultBody'>
							<span>Note: </span>You need to have an actual{" "}
							<span>Piano / Keyboard</span> to see our AI in
							action.
						</h3>
					</div>
				</div>
			)}
		</div>
	);
}
