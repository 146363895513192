import axios from "axios";
import { CONFIG } from "../../config";
export const LoginServices = async (loginData) => {
	try {
		const response = await axios.post(
			`${CONFIG.AUTHURL}/api/auth/login`,
			loginData
		);
		return response.data;
	} catch (error) {
		console.error(error);
	}
};
