import axios from "axios";
import { CONFIG } from "../../config";
export const getSubCoursesWithSubCourseId = async (subCoursesId) => {
	try {
		const response = await axios.get(
			`${CONFIG.COURSEURL}/api/subcourses/sub/all/` + subCoursesId
		);
		return response.data;
	} catch (error) {
		// returning blank array after getting server and internet error
		return [];
	}
};
