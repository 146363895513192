// importing the header css for header page stylings
import "./styles/Header.css";
import { CONFIG } from "../../../../../config";
// importing usestate for updating the used variables
import { useState, useEffect, useRef } from "react";

// importing javascript cookies for setting and getting global cookies
import Cookies from "js-cookie";

// importing the jwt decode for decoding the tokens details
import jwt_decode from "jwt-decode";

// importing the hello greeting icon for greeting the users
import helloGreetIcon from "../../../../../assets/HelloGirlIcon.png";
import helloBoyGreetIcon from "../../../../../assets/HelloBoyIcon.png";

// importing the notification bell icons for the users notifications
import notificationIcon from "../../../../../assets/NotificationIcon.svg";

// importing the user static profile logo for showing users presence
import userProfileLogo from "../../../../../assets/UserProfileLogo.png";

// importing manage account absolute card for updating profile details
import ManageAccount from "../manageAccount/ManageAccount";

// importing the user profile details service from the student management system
import { GetUserProfileDetails } from "../../../../../services/profile/GetUserProfileDetails";

export default function Header() {
	// declaring token variable for getting global authToken
	const token = Cookies.get("authToken");

	// declaring user for user details from token decode
	const user = jwt_decode(token);
	const [userProfileDirectPhoto, setUserProfileDirectPhoto] = useState(null);
	// making the manage account variable for making the popup
	const [manageAccount, setManageAccount] = useState(false);

	// declaring the function onclick manage account for showing the popup
	const onClickManageAccount = (event) => {
		// checking the manage account for showing the manage account popup
		manageAccount === false
			? setManageAccount(true)
			: setManageAccount(false);
	};

	function useOutsideAlerter(ref) {
		useEffect(() => {
			function handleOutsideClick(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setManageAccount(false);
				}
			}
			document.addEventListener("click", handleOutsideClick);
			return () =>
				document.removeEventListener("click", handleOutsideClick);
		}, [ref]);
	}
	const box = useRef(null);
	useOutsideAlerter(box);
	useEffect(() => {
		GetUserProfileDetails(user.id).then((results) => {
			setUserProfileDirectPhoto(results.dpurl);
		});
		const keyDownHandler = (event) => {
			if (event.key === "Escape") {
				setManageAccount(false);
			}
		};
		document.addEventListener("keydown", keyDownHandler);
		return () => document.addEventListener("keydown", keyDownHandler);
	}, []);
	return (
		<div className='HeaderWrapper'>
			{/* divided header wrapper into two half wrappers for keeping space between */}
			<div className='HeaderLeftWrapper'>
				{/* left wrapper contains heading and hello image with display flex */}
				<h1>
					Hello
					<br />
					{/* directly using decoded username because internet will not make issue */}
					{user.firstName} {user.lastName}
				</h1>
				{/* will add additional moving greeting video shortly */}
				{user.userGender === "male" || user.userGender === "Male" ? (
					<img src={helloBoyGreetIcon} alt='helloGreetIcon' />
				) : (
					<img src={helloGreetIcon} alt='helloGreetIcon' />
				)}
			</div>
			<div className='HeaderRightWrapper'>
				{/* added onclick function into images for reducing link routers */}
				<img
					src={notificationIcon}
					className='HeaderRightBellIcon'
					alt='helloGreetIcon'
				/>
				{/* above bell icon will have onclick for showing notification */}
				<img
					src={
						userProfileDirectPhoto === "NULL" ||
						userProfileDirectPhoto === null
							? userProfileLogo
							: CONFIG.STUDENTURL + userProfileDirectPhoto
					}
					className='HeaderRightProfileIcon'
					alt='helloGreetIcon'
					ref={box}
					onClick={() => {
						onClickManageAccount();
					}}
				/>
				{/* added manage account screen after clicking the profile icon */}
				{manageAccount === true ? (
					<ManageAccount
						userProfileDirectPhoto={userProfileDirectPhoto}
					/>
				) : null}
			</div>
		</div>
	);
}
