// importing the components for getting input fields and buttons
import BugReportLeftImg from "./components/BugReportLeftImg";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { useMessageContext } from "../../../hooks/UseMessageContext";

export default function BugReportScreen() {
  const { dispatch2 } = useMessageContext();
  const { messageArray } = useMessageContext();
  const decode = jwt_decode(Cookies.get("authToken"));
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_1966slh",
        "template_ch02n6y",
        form.current,
        "-NkAn5irJVYpd9pIN"
      )
      .then(
        (result) => {
          dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Success: ThankYou for the Feedback"] });
          console.log(result.text);
        },
        (error) => {
          dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Unfortunately Feedback not submitted"] });
          console.log(error.text);
        }
      );
  };
  return (
    <div className="HomeContextContainer">
      <BugReportLeftImg />
      <div className="BugReportScreenLoginFields">
        <h3>Report a BUG!</h3>
        {/* added the below division for keeping inputs in middle */}
        <div className="BugReportScreenInputFields">
          <form ref={form} onSubmit={sendEmail}>
            <input
              value={decode.firstName + " " + decode.lastName}
              autoComplete="password"
              placeholder="Your Full Name"
              name="fromName"
            />
            <input
              value={decode.email}
              autoComplete="password"
              placeholder="Your Email Address"
              name="fromEmail"
            />
            <input
              name="websiteUrls"
              autoComplete="password"
              placeholder="URL where problem occured"
            />
            <input
              name="problemDescription"
              autoComplete="password"
              placeholder="Problem description"
            />
            <input
              name="operatingSystem"
              autoComplete="password"
              placeholder="Your Operating Stystem"
            />
            {/* <button disabled={true} className="BugReportScreenScreenShotButton">
              Attach your Screenshot
              <input type="file" accept="image/*" />
            </button> */}
            <button value="Send" className="BugReportScreenSubmitButton">
              Submit a BUG!
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
