import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from "./context/AuthContext";
import { MessageContextProvider } from "./context/MessageContext";
import { PopupContextProvider } from "./context/PopupContext";
import { PlayerContextProvider } from "./context/PlayerContext";

ReactDOM.render(
	<React.StrictMode>
		<AuthContextProvider>
			<MessageContextProvider>
				<PlayerContextProvider>
					<PopupContextProvider>
						<App />
					</PopupContextProvider>
				</PlayerContextProvider>
			</MessageContextProvider>
		</AuthContextProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
