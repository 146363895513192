import axios from "axios";
import { CONFIG } from "../../config";
export const getQuestionService = async (quizID) => {
	try {
		const response = await axios.get(
			`${CONFIG.COURSEURL}/api/quiz/` + quizID
		);
		// console.log(response.data);
		return response.data;
	} catch (error) {
		// returning blank array after getting server and internet error
		return { questions: [], answers: [] };
	}
};
