// left components stylesheets were present inside global auth css
// importing the logo and the welcome images from assets
import logo from "../../../../assets/BlackLogo.png";
import forgetPassword from "../../../../assets/ForgotPasswordNew.png";

export default function LeftComponent() {
  return (
    <div className="LeftComponentWrapper">
      <div className="LeftComponentLogo">
        <img src={logo} alt="logo" />
      </div>
      <div className="LeftComponentWelcome">
        <img src={forgetPassword} alt="forgetPassword" />
      </div>
    </div>
  );
}
