import axios from "axios";
import { CONFIG } from "../../config";
export const getSubCoursesService = async (coursesId) => {
	try {
		const response = await axios.get(
			`${CONFIG.COURSEURL}/api/subcourses/course/all/` + coursesId
		);
		return response.data;
	} catch (error) {
		// returning blank array after getting server and internet error
		return [];
	}
};
