// importing the global home styles for global stylings
import "./global/styles/GlobalHome.css";

// importing the useeffect for removing rerendering
// import { useEffect } from "react";

// importing the home container which acts as home frame
import HomeContainer from "../global/HomeContainer";

// importing the sidebar from the global home components
import Sidebar from "./global/components/Sidebar/Sidebar";

// importing the header from the global home components
import Header from "./global/components/Header/Header";

// importing profile screen showing the profile inside home
import ProfileScreen from "./profile/profileScreen/ProfileScreen";

// importing manage profile screen for managing the profile details
import ProfileManage from "./profile/profileManage/ProfileManage";

// importing the zoom screen for student teacher meetings
import ZoomScreen from "./zoomScreen/ZoomScreen";

// importing the bug report screen for reporting the lms bugs
import BugReportScreen from "./bugReportScreen/BugReportScreen";

// importing dashboard screen for for showing uncompleted courses
import DashboardScreen from "./dashboardScreen/DashboardScreen";

// importing the courses screen for showing the available courses
import CoursesIndexScreen from "./coursesScreen/coursesIndexScreen/CoursesIndexScreen";

// importing the subcourses screen for showing the subcourses
import SubCoursesIndexScreen from "./coursesScreen/subCoursesIndexScreen/SubCoursesIndexScreen";

// importing the lessons screen for showing the available lessons
import LessonsScreen from "./lessonsScreen/LessonsScreen";

// importing audio evaluation screen for showing inside home container
import AudioEvaluation from "./evaluation/audioEvaluation/AudioEvaluation";

// importing realtime evaluation screen for showing inside home container
import RealtimeEvaluation from "./evaluation/realtimeEvaluation/RealtimeEvaluation";

// importing the make session update service for making student online
// import { MakeSessionUpdate } from "../../services/auth/MakeSessionUpdate";

// importing javascript cookies for setting and getting global cookies
// import Cookies from "js-cookie";

// importing the token decode library for decoding the global tokens
// import decode from "jwt-decode";

// importing route and routes for changing feature screens
import { Route, Routes } from "react-router-dom";
import DocumentScreen from "./documentScreen/DocumentScreen";

export default function Home() {
	// // uncomment the below code after for getting students attendace
	// useEffect(() => {
	//   // declared value variable for getting the global setted authToken
	//   const value = decode(Cookies.get("authToken"));
	//   // below code block makes api request into backend after every half hour
	//   const intervalCall = setInterval(() => {
	//     MakeSessionUpdate({
	//       id: value.id,
	//       userName: value.firstName,
	//       userType: value.user_type,
	//       isOnline: true,
	//     }).then(console.log("api executed"));
	//   }, 60000 * 30);
	//   return () => {
	//     clearInterval(intervalCall);
	//   };
	// }, []);
	return (
		<HomeContainer>
			<Sidebar />
			<div className='HomeRightSideWrapper'>
				<Header />
				<Routes>
					<Route element={<DashboardScreen />} path='' />
					<Route element={<ProfileScreen />} path='/profile' />
					<Route element={<ZoomScreen />} path='/liveclasses' />
					<Route element={<ProfileManage />} path='/manage' />
					<Route element={<BugReportScreen />} path='/report' />
					<Route element={<CoursesIndexScreen />} path='/courses' />
					<Route
						element={<SubCoursesIndexScreen />}
						path='/subcourses'
					/>
					<Route element={<LessonsScreen />} path='/lessons' />
					<Route element={<AudioEvaluation />} path='/evaluation' />
					<Route element={<RealtimeEvaluation />} path='/realtime' />
					<Route element={<DocumentScreen />} path='/document' />
				</Routes>
			</div>
		</HomeContainer>
	);
}
