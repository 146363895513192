import { PopupContext } from "../context/PopupContext";
import { useContext } from "react";

export const usePopupContext = () => {
	const context = useContext(PopupContext);
	if (!context) {
		throw Error(
			"usePopupContext must be used inside an PopupContextProvider"
		);
	}
	return context;
};
