import axios from "axios";
import { CONFIG } from "../../config";
export const downloadModuleURLService = async (moduleId) => {
	try {
		const response = await axios.get(
			`${CONFIG.COURSEURL}/api/modules/get-module/` + moduleId
		);
		return response.data;
	} catch (error) {
		// returning blank array after getting server and internet error
		return [];
	}
};
