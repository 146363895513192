import "./styles/EvaluationResultCard.css";
import { markVideoCompleted } from "../../../../services/dripFeeding/MarkVideoCompleted";
import { SaveAnalyticsScoreService } from "../../../../services/dripFeeding/SaveAnalyticsScoreService";
import { useEffect, useState } from "react";

export default function EvaluationResultCard(props) {
	const EvaulatedKey = (masterNotes, evaluatedNotes) => {
		let correctNotes = [];
		for (let i = 0; i < masterNotes.length; i++) {
			if (masterNotes[i] === evaluatedNotes[i]) {
				correctNotes.push(true);
			} else {
				correctNotes.push(false);
			}
		}
		// console.log(props.accuracy);
		return correctNotes;
	};
	const RewampedEvaulatedKey = (masterNotes, evaluatedNotes) => {
		let correctNotes = [];
		let previousEvaluatedNotes = "";
		let masterLenthCounter = 0;
		let evaluatedLenthCounter = 0;
		while (
			evaluatedLenthCounter < evaluatedNotes.length &&
			masterLenthCounter < masterNotes.length
		) {
			if (
				masterNotes[masterLenthCounter] ===
				evaluatedNotes[evaluatedLenthCounter]
			) {
				previousEvaluatedNotes = evaluatedNotes[evaluatedLenthCounter];
				masterLenthCounter = masterLenthCounter + 1;
				evaluatedLenthCounter = evaluatedLenthCounter + 1;
				correctNotes.push(true);
			}
			// else if (
			//   masterNotes[masterLenthCounter] !==
			//     evaluatedNotes[evaluatedLenthCounter] &&
			//   evaluatedNotes[evaluatedLenthCounter] === previousEvaluatedNotes
			// ) {
			//   evaluatedLenthCounter = evaluatedLenthCounter + 1;
			//   correctNotes.push(false);
			// }
			else if (
				masterNotes[masterLenthCounter] !==
				evaluatedNotes[evaluatedLenthCounter]
			) {
				// masterLenthCounter = masterLenthCounter + 1;
				// evaluatedLenthCounter = evaluatedLenthCounter + 1;
				// correctNotes.push(false);

				if (
					evaluatedNotes[evaluatedLenthCounter] &&
					evaluatedNotes[evaluatedLenthCounter].length >
						masterNotes[masterLenthCounter].length
				) {
					const slicedCount =
						evaluatedNotes[evaluatedLenthCounter].length -
						masterNotes[masterLenthCounter].length;
					evaluatedNotes[evaluatedLenthCounter] = evaluatedNotes[
						evaluatedLenthCounter
					].slice(
						slicedCount,
						evaluatedNotes[evaluatedLenthCounter].length
					);
					if (
						masterNotes[masterLenthCounter] ===
						evaluatedNotes[evaluatedLenthCounter]
					) {
						previousEvaluatedNotes =
							evaluatedNotes[evaluatedLenthCounter];
						masterLenthCounter = masterLenthCounter + 1;
						evaluatedLenthCounter = evaluatedLenthCounter + 1;
						correctNotes.push(true);
					} else {
						evaluatedLenthCounter = evaluatedLenthCounter + 1;
						correctNotes.push(false);
					}
				} else {
					if (
						masterNotes[masterLenthCounter] ===
						evaluatedNotes[evaluatedLenthCounter]
					) {
						previousEvaluatedNotes =
							evaluatedNotes[evaluatedLenthCounter];
						masterLenthCounter = masterLenthCounter + 1;
						evaluatedLenthCounter = evaluatedLenthCounter + 1;
						correctNotes.push(true);
					} else {
						evaluatedLenthCounter = evaluatedLenthCounter + 1;
						correctNotes.push(false);
					}
				}
			}
		}
		return correctNotes;
	};
	const rewampedGreenNotesFinder = (correctNotes) => {
		let greenNotesCounter = 0;
		let countingTrueNotes = 0;
		while (greenNotesCounter < correctNotes.length) {
			if (correctNotes[greenNotesCounter] === true) {
				countingTrueNotes = countingTrueNotes + 1;
				greenNotesCounter = greenNotesCounter + 1;
			} else {
				greenNotesCounter = greenNotesCounter + 1;
			}
		}
		return countingTrueNotes;
	};

	const sortedMasterNotes = props.actualNotes.map((card) =>
		card.split(" ").sort().join(" ")
	);
	const sortedEvaluatedNotes = props.notesPlayed.map((card) =>
		card.split(" ").sort().join(" ")
	);

	let matchingNotes = [];

	for (
		let i = 0;
		i < sortedMasterNotes.length || i < sortedEvaluatedNotes.length;
		i++
	) {
		const masterCard = (sortedMasterNotes[i] || "").split(" ");
		const evaluatedCard = (sortedEvaluatedNotes[i] || "").split(" ");

		let matchingCard = [];

		for (let j = 0; j < masterCard.length; j++) {
			if (evaluatedCard.includes(masterCard[j])) {
				matchingCard.push(masterCard[j]);
			}
		}

		if (matchingCard.length === 0) {
			// If no matching notes, include the entire evaluated card
			matchingCard = evaluatedCard;
		}

		matchingNotes.push(matchingCard.join(" "));
	}

	console.log("matchingNotes", matchingNotes);
	console.log("sortedEvaluatedNotes", sortedEvaluatedNotes);
	console.log("sortedMasterNotes", sortedMasterNotes);

	const KEYS = EvaulatedKey(sortedMasterNotes, matchingNotes);
	const greenNotesCount = rewampedGreenNotesFinder(KEYS);
	const revampedKEYS = RewampedEvaulatedKey(sortedMasterNotes, matchingNotes);
	const greenNotesCount2 = rewampedGreenNotesFinder(revampedKEYS);

	const checkGreaterAccuracy = (
		KEYS,
		greenNotesCount,
		revampedKEYS,
		greenNotesCount2
	) => {
		if (
			Math.ceil((greenNotesCount / sortedMasterNotes.length) * 100) >=
			Math.ceil((greenNotesCount2 / sortedMasterNotes.length) * 100)
		) {
			return [KEYS, greenNotesCount];
		} else {
			return [revampedKEYS, greenNotesCount2];
		}
	};
	const checkGreaterAccuracyVars = checkGreaterAccuracy(
		KEYS,
		greenNotesCount,
		revampedKEYS,
		greenNotesCount2
	);
	useEffect(() => {
		var studentScore =
			(checkGreaterAccuracyVars[1] / props.actualNotes.length) * 100;
		if (Math.ceil(studentScore) > 70) {
			SaveAnalyticsScoreService({
				user_id: parseInt(props.studentID),
				piece_id: parseInt(props.pieceId),
				student_id: parseInt(props.studentID),
				score: studentScore,
				played_notes: matchingNotes.filter((item) => item !== ""),
				master_notes: sortedMasterNotes,
				// courseId: parseInt(props.courseId),
			});
			if (parseInt(props.pieceId) === 82) {
				const videosDetails1 = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId) + 1,
					videoId: parseInt(props.pieceId) + 1,
				};
				const videosDetails2 = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId) + 1,
					videoId: parseInt(props.pieceId) + 2,
				};
				const videosDetails3 = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId) + 1,
					videoId: parseInt(props.pieceId) + 3,
				};
				markVideoCompleted(videosDetails1).then(() => {
					markVideoCompleted(videosDetails2).then(() => {
						markVideoCompleted(videosDetails3).then(() => {
							// alert("Congratulation you have unlocked the next Lesson");
						});
					});
				});
			} else if (parseInt(props.pieceId) === 220) {
				const videosDetails1 = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId),
					videoId: parseInt(props.pieceId) + 1,
				};
				const videosDetails2 = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId),
					videoId: parseInt(props.pieceId) + 2,
				};
				const videosDetails3 = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId),
					videoId: parseInt(props.pieceId) + 3,
				};
				markVideoCompleted(videosDetails1).then(() => {
					markVideoCompleted(videosDetails2).then(() => {
						markVideoCompleted(videosDetails3).then(() => {
							// alert("Congratulation you have unlocked the next Lesson");
						});
					});
				});
			} else if (parseInt(props.pieceId) === 161) {
				const videosDetails1 = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId),
					videoId: parseInt(props.pieceId) + 1,
				};
				const videosDetails2 = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId) + 1,
					videoId: parseInt(props.pieceId) + 2,
				};
				markVideoCompleted(videosDetails1).then(() => {
					markVideoCompleted(videosDetails2).then(() => {
						// alert("Congratulation you have unlocked the next Lesson");
					});
				});
			} else if (
				parseInt(props.pieceId) === 230 ||
				parseInt(props.pieceId) === 312
			) {
				const videosDetails1 = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId) + 1,
					videoId: parseInt(props.pieceId) + 1,
				};
				const videosDetails2 = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId) + 1,
					videoId: parseInt(props.pieceId) + 2,
				};
				markVideoCompleted(videosDetails1).then(() => {
					markVideoCompleted(videosDetails2).then(() => {
						// alert("Congratulation you have unlocked the next Lesson");
					});
				});
			} else if (parseInt(props.pieceId) === 276) {
				const videosDetails1 = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId) + 1,
					videoId: parseInt(props.pieceId) + 8,
				};
				const videosDetails2 = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId) + 1,
					videoId: parseInt(props.pieceId) + 9,
				};
				markVideoCompleted(videosDetails1).then(() => {
					markVideoCompleted(videosDetails2).then(() => {
						// alert("Congratulation you have unlocked the next Lesson");
					});
				});
			} else if (parseInt(props.pieceId) === 306) {
				const videosDetails = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId) + 4,
					videoId: parseInt(props.pieceId) + 1,
				};
				markVideoCompleted(videosDetails).then(() => {
					// alert("Congratulation you have unlocked the next Lesson");
				});
			} else if (parseInt(props.pieceId) === 478) {
				const videosDetails = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId),
					videoId: 520,
				};
				markVideoCompleted(videosDetails).then(() => {
					// alert("Congratulation you have unlocked the next Lesson");
				});
			} else if (
				parseInt(props.pieceId) === 164 ||
				parseInt(props.pieceId) === 188 ||
				parseInt(props.pieceId) === 196 ||
				parseInt(props.pieceId) === 199 ||
				parseInt(props.pieceId) === 208 ||
				parseInt(props.pieceId) === 211 ||
				parseInt(props.pieceId) === 224 ||
				parseInt(props.pieceId) === 233 ||
				parseInt(props.pieceId) === 235 ||
				parseInt(props.pieceId) === 241 ||
				parseInt(props.pieceId) === 244 ||
				parseInt(props.pieceId) === 251 ||
				parseInt(props.pieceId) === 253 ||
				parseInt(props.pieceId) === 259 ||
				parseInt(props.pieceId) === 262 ||
				parseInt(props.pieceId) === 270 ||
				parseInt(props.pieceId) === 278 ||
				parseInt(props.pieceId) === 286 ||
				parseInt(props.pieceId) === 288 ||
				parseInt(props.pieceId) === 295 ||
				parseInt(props.pieceId) === 298 ||
				parseInt(props.pieceId) === 300 ||
				parseInt(props.pieceId) === 302 ||
				parseInt(props.pieceId) === 307 ||
				parseInt(props.pieceId) === 316
			) {
				const videosDetails1 = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId),
					videoId: parseInt(props.pieceId) + 1,
				};
				const videosDetails2 = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId),
					videoId: parseInt(props.pieceId) + 2,
				};
				markVideoCompleted(videosDetails1).then(() => {
					markVideoCompleted(videosDetails2).then(() => {
						// alert("Congratulation you have unlocked the next Lesson");
					});
				});
			} else if (
				parseInt(props.pieceId) === parseInt(props.courseLastLessonID)
			) {
				const videosDetails = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId) + 1,
					videoId: parseInt(props.pieceId) + 1,
				};
				markVideoCompleted(videosDetails).then(() => {
					// alert("Congratulation you have unlocked the next Lesson");
				});
			} else {
				const videosDetails = {
					studentId: parseInt(props.studentID),
					courseId: parseInt(props.courseId),
					subCourseId: parseInt(props.subCoursesId),
					videoId: parseInt(props.pieceId) + 1,
				};
				markVideoCompleted(videosDetails).then(() => {
					// alert("Congratulation you have unlocked the next Lesson");
				});
			}
		} else {
			SaveAnalyticsScoreService({
				user_id: parseInt(props.studentID),
				piece_id: parseInt(props.pieceId),
				student_id: parseInt(props.studentID),
				score: studentScore,
				played_notes: matchingNotes.filter((item) => item !== ""),
				master_notes: sortedMasterNotes,
				// courseId: parseInt(props.courseId),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [(checkGreaterAccuracyVars[1] / props.actualNotes.length) * 100]);
	// Math.ceil( (checkGreaterAccuracyVars[1] / props.actualNotes.length) * 100 ),
	return (
		<div>
			<h3 className='EvaluationResultCardUserScore'>
				Your Score:{" "}
				{Math.ceil(
					(checkGreaterAccuracyVars[1] / sortedMasterNotes.length) *
						100
				)}
				%
			</h3>
			<div className='EvaluationResultCardProgressBars'>
				<hr
					className='EvaluationResultCardProgressBar1'
					style={{
						width:
							Math.ceil(
								(checkGreaterAccuracyVars[1] /
									sortedMasterNotes.length) *
									100
							) + "%",
					}}
				/>
			</div>
			<h3 className='EvaluationResultCardComment EvaluationResultCardFontSize'>
				{Math.ceil(
					(checkGreaterAccuracyVars[1] / sortedMasterNotes.length) *
						100
				) > 70 ? (
					<em>
						<span>Congratulations</span>, you have unlocked the next
						Lesson
					</em>
				) : (
					<em>
						Practice is the key to excellence, please try again
						after practicing
					</em>
				)}
			</h3>
			<div className='Result'>
				<h3 className='ResultHeading EvaluationResultCardFontSize'>
					{props.title}
				</h3>
				<div className='Prediction'>
					<h3 className='EvaluationResultCardFontSize'>
						Notes You Played :{" "}
					</h3>
					<div className='NoteLists'>
						{matchingNotes
							.filter((item, index) => item !== "")
							.map((_, index) => {
								if (index < matchingNotes.length) {
									return checkGreaterAccuracyVars[0][
										index
									] ? (
										<div className='note right' key={index}>
											<h3>{matchingNotes[index]}</h3>
										</div>
									) : (
										<div className='note wrong' key={index}>
											<h3>{matchingNotes[index]}</h3>
										</div>
									);
								}
								return null;
							})}
					</div>
				</div>
				<div className='MasterNotes'>
					<h3 className='EvaluationResultCardFontSize'>
						Master Notes :{" "}
					</h3>
					<div className='NoteLists'>
						{sortedMasterNotes.map((notes, index) => (
							<div className='note' key={index}>
								<h3>{notes}</h3>
							</div>
						))}
					</div>
				</div>
				{/* <div className="RevampedNotes">
        <h3>Original Evaluated Notes : </h3>
        <div className="NoteLists">
          {sortedEvaluatedNotes.map((notes, index) => (
            <div className="note" key={index}>
              {notes}
            </div>
          ))}
        </div>
      </div> */}
			</div>
		</div>
	);
}

// Anshul Code with master length tweek and slicing
// ------------------------------------------------------------

// const EvaulatedKey = (masterNotes, evaluatedNotes) => {
//   let correctNotes = [];
//   for (let i = 0; i < masterNotes.length; i++) {
//     if (masterNotes[i] === evaluatedNotes[i]) {
//       correctNotes.push(true);
//     } else {
//       correctNotes.push(false);
//     }
//   }
//   // console.log(props.accuracy);
//   return correctNotes;
// };

//   // Function to handle the length of evaluated notes
//   const handleEvaluatedNotes = (evaluatedNotes) => {
//     if (evaluatedNotes.length === 1) {
//       return evaluatedNotes[0];
//     } else {
//       // Show only the first note and ignore the rest
//       return evaluatedNotes.slice(0, 1);
//     }
//   };

//   const greenNotesFinder = (masterNotes, evaluatedNotes) => {
//     let greenNotesCounter = 0;
//     for (let i = 0; i < masterNotes.length; i++) {
//       if (masterNotes[i] === evaluatedNotes[i]) {
//         greenNotesCounter = greenNotesCounter + 1;
//       }
//     }
//     return greenNotesCounter;
//   };

//   // Create a new array with evaluated notes, including sliced notes
//   const updatedEvaluatedNotes = props.notesPlayed.map((notes) =>
//     handleEvaluatedNotes(notes)
//   );

//   const KEYS = EvaulatedKey(props.actualNotes, updatedEvaluatedNotes);
//   const greenNotesCount = greenNotesFinder(
//     props.actualNotes,
//     updatedEvaluatedNotes
//   );

//   return (
//     <div className="Result">
//       <h3 className="ResultHeading">{props.title}</h3>
//       {/* the predicted notes are listed */}
//       <div className="Prediction">
//         <h3>Notes You Played : </h3>
//         <div className="NoteLists">
//           {/* Iterate only up to the length of master notes */}
//           {props.actualNotes.map((_, index) => {
//             // Check if the index is within the bounds of the evaluated notes
//             if (index < updatedEvaluatedNotes.length) {
//               return KEYS[index] ? (
//                 <div className="note right" key={index}>
//                   {updatedEvaluatedNotes[index]}
//                 </div>
//               ) : (
//                 <div className="note wrong" key={index}>
//                   {updatedEvaluatedNotes[index]}
//                 </div>
//               );
//             }
//             return null; // If index is beyond the length of evaluated notes, return null
//           })}
//         </div>
//       </div>
//       {/* the master notes are listed */}
//       <div className="MasterNotes">
//         <h3>Master Notes : </h3>
//         <div className="NoteLists">
//           {props.actualNotes.map((notes, index) => (
//             <div className="note" key={index}>
//               {notes}
//             </div>
//           ))}
//         </div>
//       </div>
//       <h3>
//         Your Score:{" "}
//         {Math.ceil((greenNotesCount / props.actualNotes.length) * 100)}%
//       </h3>
//       <h3>
//         <em>
//           {Math.ceil((greenNotesCount / props.actualNotes.length) * 100) <= 84
//             ? "Practice is the key to excellence. Please try again after practicing."
//             : "You played excellently. Continue to shine and always keep going on."}
//         </em>
//       </h3>
//     </div>
//   );
// }

// srittam code
// ------------------------------------------------------------
//   const EvaulatedKey = (masterNotes, evaluatedNotes) => {
//     let correctNotes = [];
//     for (let i = 0; i < masterNotes.length; i++) {
//       if (masterNotes[i] === evaluatedNotes[i]) {
//         correctNotes.push(true);
//       } else {
//         correctNotes.push(false);
//       }
//     }
//     // console.log(props.accuracy);
//     return correctNotes;
//   };

//   const greenNotesFinder = (masterNotes, evaluatedNotes) => {
//     let greenNotesCounter = 0;
//     for (let i = 0; i < masterNotes.length; i++) {
//       if (masterNotes[i] === evaluatedNotes[i]) {
//         greenNotesCounter = greenNotesCounter + 1;
//       }
//     }
//     return greenNotesCounter;
//   };
//   const KEYS = EvaulatedKey(props.actualNotes, props.notesPlayed);
//   const greenNotesCount = greenNotesFinder(
//     props.actualNotes,
//     props.notesPlayed
//   );

//   return (
//     <div className="Result">
//       <h3 className="ResultHeading">{props.title}</h3>
//       {/* the perdicted notes are listed */}
//       <div className="Prediction">
//         <h3>Notes You Played : </h3>
//         <div className="NoteLists">
//           {props.notesPlayed.map((notes, index) => {
//             return KEYS[index] ? (
//               <div className="note right">{notes}</div>
//             ) : (
//               <div className="note wrong">{notes}</div>
//             );
//           })}
//         </div>
//       </div>
//       {/* <div className="MasterNotes">
//         <h3>Extracted Notes : </h3>
//         <div className="NoteLists">
//           {props.notesPlayed.map((notes) => {
//             return <div className="note">{notes}</div>;
//           })}
//         </div>
//       </div> */}
//       {/* the master notes are listed */}
//       <div className="MasterNotes">
//         <h3>Master Notes : </h3>
//         <div className="NoteLists">
//           {props.actualNotes.map((notes) => {
//             return <div className="note">{notes}</div>;
//           })}
//         </div>
//       </div>
//       <h3>
//         Your Score:{" "}
//         {Math.ceil((greenNotesCount / props.actualNotes.length) * 100)}%
//       </h3>
//       <h3>
//         <em>
//           {Math.ceil((greenNotesCount / props.actualNotes.length) * 100) <= 84
//             ? "Practice is the key to excellence. Please try again after practicing."
//             : "You played excellently. Continue to shine and always keep going on."}
//         </em>
//       </h3>
//     </div>
//   );
// }
