import axios from "axios";
import { CONFIG } from "../../config";
export const GetUserProfileDetails = async (userID) => {
	try {
		const response = await axios.get(
			`${CONFIG.STUDENTURL}/api/students/get-student/` + userID
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return [];
	}
};
