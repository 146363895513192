// importing the use state for updating local variables
import { useEffect, useState } from "react";
import { CONFIG } from "../../../../config";
// importing the global css for aquiring global css
import "../styles/GlobalProfile.css";

// importing the dashboard screen css for the stylings
import "./styles/ProfileScreen.css";

// importing javascript cookies for setting and getting global cookies
import Cookies from "js-cookie";

// importing the user details service for getting the user details
import { userDetailsService } from "../../../../services/userDetails/UserDetailsServices";

// importing the user profile details service from the student management system
import { GetUserProfileDetails } from "../../../../services/profile/GetUserProfileDetails";

// importing the profile description from the components
import ProfileDescription from "./components/ProfileDescription";

// importing the logos and the social icons from the assets
import userProfileLogo from "../../../../assets/UserProfileLogo.png";
import profileFacebookIcon from "../../../../assets/profileIcons/FacebookIcon.svg";
import profileInstagramIcon from "../../../../assets/profileIcons/InstagramIcon.svg";
import profileTwitterIcon from "../../../../assets/profileIcons/TwitterIcon.svg";
import profileYoutubeIcon from "../../../../assets/profileIcons/YoutubeIcon.svg";
import profileLinkedinIcon from "../../../../assets/profileIcons/LinkedinIcon.svg";

import profileFacebookColorIcon from "../../../../assets/profileIcons/FacebookColorIcon.svg";
import profileInstagramColorIcon from "../../../../assets/profileIcons/InstagramColorIcon.svg";
import profileTwitterColorIcon from "../../../../assets/profileIcons/TwitterColorIcon.svg";
import profileYoutubeColorIcon from "../../../../assets/profileIcons/YoutubeColorIcon.svg";
import profileLinkedinColorIcon from "../../../../assets/profileIcons/LinkedinColorIcon.svg";

export default function ProfileScreen() {
	// added these change profile icons variable for changing colour onhover
	const [changeFacebookIcon, setChangeFacebookIcon] = useState(false);
	const [changeInstagramIcon, setChangeInstagramIcon] = useState(false);
	const [changeTwitterIcon, setChangeTwitterIcon] = useState(false);
	const [changeYoutubeIcon, setChangeYoutubeIcon] = useState(false);
	const [changeLinkedinIcon, setChangeLinkedinIcon] = useState(false);

	// declaring the below variables for showing the user details
	const [userFirstName, setUserFirstName] = useState("");
	const [userLastName, setUserLastName] = useState("");
	const [userDOB, setUserDOB] = useState("");
	const [UserMobile, setUserMobile] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [userProfileDirectPhoto, setUserProfileDirectPhoto] = useState(null);
	const [userDescription, setUserDescription] = useState();
	const [userFacebookLink, setUserFacebookLink] = useState();
	const [userInstagramLink, setUserInstagramLink] = useState();
	const [userTwitterLink, setUserTwitterLink] = useState();
	const [userYoutubeLink, setUserYoutubeLink] = useState();
	const [userLinkedinLink, setUserLinkedinLink] = useState();

	useEffect(() => {
		// declaring token variable for getting global authToken
		const token = Cookies.get("authToken");

		// adding imported user details service for showing userdetails
		userDetailsService(token)
			.then((result) => {
				setUserFirstName(result.firstName);
				setUserLastName(result.lastName);
				setUserDOB(result.dob);
				setUserMobile(result.phoneNo);
				setUserEmail(result.email);
				GetUserProfileDetails(result.id).then((results) => {
					setUserProfileDirectPhoto(results.dpurl);
					setUserDescription(results.bio);
					setUserInstagramLink(results.instagram);
					setUserFacebookLink(results.facebook);
					setUserTwitterLink(results.x);
					setUserYoutubeLink(results.youtube);
					setUserLinkedinLink(results.linkedIn);
				});
			})
			.catch((e) => console.log(e));
	}, []);
	return (
		<div className='HomeContextContainer'>
			<div className='GlobalProfileDetails'>
				<div className='GlobalProfileDetailsList'>
					<img
						src={
							userProfileDirectPhoto === "NULL" ||
							userProfileDirectPhoto === null
								? userProfileLogo
								: CONFIG.STUDENTURL + userProfileDirectPhoto
						}
						alt='userProfileLogo'
					/>
					<p>
						<strong>
							{userFirstName} {userLastName}
						</strong>
					</p>
					<p>{userDOB}</p>
					<p>{UserMobile}</p>
					<p>{userEmail}</p>
				</div>
				<div className='ProfileScreenDetailsSocial'>
					<img
						src={
							changeFacebookIcon
								? profileFacebookColorIcon
								: profileFacebookIcon
						}
						alt='profileFacebookIcon'
						onMouseOver={() => setChangeFacebookIcon(true)}
						onMouseOut={() => setChangeFacebookIcon(false)}
						onClick={() => window.open(userFacebookLink, "_blank")}
					/>
					<img
						src={
							changeInstagramIcon
								? profileInstagramColorIcon
								: profileInstagramIcon
						}
						alt='profileInstagramIcon'
						onMouseOver={() => setChangeInstagramIcon(true)}
						onMouseOut={() => setChangeInstagramIcon(false)}
						onClick={() => window.open(userInstagramLink, "_blank")}
					/>
					<img
						src={
							changeTwitterIcon
								? profileTwitterColorIcon
								: profileTwitterIcon
						}
						alt='profileTwitterIcon'
						onMouseOver={() => setChangeTwitterIcon(true)}
						onMouseOut={() => setChangeTwitterIcon(false)}
						onClick={() => window.open(userTwitterLink, "_blank")}
					/>
					<img
						src={
							changeYoutubeIcon
								? profileYoutubeColorIcon
								: profileYoutubeIcon
						}
						alt='profileYoutubeIcon'
						onMouseOver={() => setChangeYoutubeIcon(true)}
						onMouseOut={() => setChangeYoutubeIcon(false)}
						onClick={() => window.open(userYoutubeLink, "_blank")}
					/>
					<img
						src={
							changeLinkedinIcon
								? profileLinkedinColorIcon
								: profileLinkedinIcon
						}
						alt='profileYoutubeIcon'
						onMouseOver={() => setChangeLinkedinIcon(true)}
						onMouseOut={() => setChangeLinkedinIcon(false)}
						onClick={() => window.open(userLinkedinLink, "_blank")}
					/>
				</div>
			</div>
			<ProfileDescription description={userDescription} />
		</div>
	);
}
