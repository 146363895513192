// using usestate for updating the variable value
import { useState, useEffect } from "react";
import { CONFIG } from "../../../config";

// importing the zoom grreting image for showing inside zoom screen
import zoomMeetingGreet from "../../../assets/ZoomMeetingGreet.png";

// importing javascript cookies for setting and getting global cookies
import Cookies from "js-cookie";

// importing the token decode library for decoding the global tokens
import decode from "jwt-decode";

export default function ZoomScreen() {
	// declared the variable teacher for setting button name
	const [teacher, setTeacher] = useState(false);

	// declared value variable for getting the global setted authToken
	const value = Cookies.get("authToken");

	// made redirect zoom variable for directing user into meeting
	function redirectZoom() {
		window.location.href = CONFIG.ZOOMURL + value;
	}
	// checking whether the authtoken gives us teacher or student
	useEffect(() => {
		// decoding the global token for getting userstype
		if (
			decode(value).user_type.toLowerCase() === "teacher" ||
			decode(value).user_type.toLowerCase() === "admin" ||
			decode(value).user_type.toLowerCase() === "Teacher" ||
			decode(value).user_type.toLowerCase() === "Admin"
		) {
			setTeacher(true);
		} else {
			setTeacher(false);
		}
	}, [value]);
	return (
		<div className='HomeContextContainer'>
			<img
				className='ZoomMeetingGreetImage'
				src={zoomMeetingGreet}
				alt='zoomMeetingGreet'
			/>
			<button className='ZoomMeetingButton' onClick={redirectZoom}>
				{teacher ? "Start Meeting" : "Join Meeting"}
			</button>
		</div>
	);
}
