import axios from "axios";
import { CONFIG } from "../../config";
export const getCoursesArrayService = async (userId) => {
	try {
		const response = await axios.get(
			`${CONFIG.STUDENTURL}/api/enrolledcourses/get-enrolledcourseuserId/` +
				userId
		);
		return response.data;
	} catch (error) {
		// returning blank array after getting server and internet error
		return [];
	}
};
