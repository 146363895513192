import axios from "axios";
import { CONFIG } from "../../config";
export const fetchDocumentsService = async (serialNumber) => {
	try {
		const response = await axios.get(
			`${CONFIG.COURSEURL}/documents/` + serialNumber
		);
		if (response.status === 200) {
			return response.data.documents.documentContent;
		} else {
			return "Some error occured while processing your document";
		}
	} catch {
		return "Some error occured while processing your document";
	}
};
