import "../styles/GlobalAuth.css";
import { useEffect, useState } from "react";
import { passwordRecovery } from "../../../services/auth/passwordRecovery";
import { useMessageContext } from "../../../hooks/UseMessageContext";
import { useNavigate } from "react-router-dom";
import LeftComponent from "./components/LeftComponent";
import AuthContainer from "../../global/AuthContainer";
import decode from "jwt-decode";
export default function RecoverPassword() {
	const navigate = useNavigate();
	const { dispatch2 } = useMessageContext();
	const { messageArray } = useMessageContext();
	const [userID, setUserID] = useState("");
	const [emailAddress, setEmailAddress] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	useEffect(() => {
		try {
			const pathname = window.location.pathname;
			const result = pathname.slice(9);
			console.log(decode(result).email);
			setEmailAddress(decode(result).email);
			setUserID(decode(result).userid);
		} catch {
			navigate("/404found");
		}
	}, [navigate]);
	function dispatchFunction(request) {
		dispatch2({
			type: "MESSAGEARRAY",
			payload: [
				...messageArray,
				request === "error"
					? "Password not changed"
					: request === "same"
					? "Passwords not same"
					: request === "blank"
					? "Please give some input"
					: request === "success"
					? "Successfully changed password"
					: "Successfull message",
			],
		});
	}
	function passwordRecoveryFn() {
		password === confirmPassword
			? confirmPassword === ""
				? dispatchFunction("blank")
				: passwordRecovery({
						userid: userID,
						email_address: emailAddress,
						password: password,
				  }).then(function (response) {
						response === "password successfully changed"
							? successfullFunction()
							: dispatchFunction("error");
				  })
			: dispatchFunction("same");
	}
	const handleKeyDown = async (event) => {
		if (event.keyCode === 13) {
			passwordRecoveryFn();
		}
	};
	function successfullFunction() {
		setConfirmPassword("");
		setPassword("");
		dispatchFunction("success");
	}
	return (
		<AuthContainer>
			<LeftComponent />
			{/* below division wraps all the login field */}
			<div className='RightComponentLoginFields'>
				{/* below division wraps the headings */}
				<div className='RightComponentHeading'>
					{/* <h1> Recover <br /> Your Password ? </h1> */}
					<h2>Password Recovery for</h2>
					<h2>{emailAddress}</h2>
				</div>
				{/* below division wraps the two input fields */}
				<div className='RightComponentInputFields'>
					<input
						autoComplete='password'
						className='InputFieldClass'
						placeholder='Password'
						type='password'
						value={password}
						onChange={function (event) {
							setPassword(event.target.value);
						}}
					/>
					<input
						autoComplete='password'
						className='InputFieldClass'
						type='password'
						placeholder='Confirm Password'
						onKeyDown={handleKeyDown}
						value={confirmPassword}
						onChange={function (event) {
							setConfirmPassword(event.target.value);
						}}
					/>
					<button onClick={() => passwordRecoveryFn()}>
						SET PASSWORD
					</button>
				</div>
			</div>
		</AuthContainer>
	);
}
