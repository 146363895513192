// importing css for giving styles into manage accountcard
import "./styles/ManageAccount.css";
import { CONFIG } from "../../../../../config";
// importing usestate for updating the used variables
import { useState } from "react";

// importing javascript cookies for setting and getting global cookies
import Cookies from "js-cookie";

// importing the jwt decode for decoding the tokens details
import jwt_decode from "jwt-decode";

// importing user profile logo from assets for showing inside popup
import userProfileLogo from "../../../../../assets/UserProfileLogo.png";

// importing the navigate for routing between different screens
import { useNavigate } from "react-router-dom";

// importing use auth context for resolving redirecting issue
import { useAuthContext } from "../../../../../hooks/UseAuthContext";

export default function ManageAccount(props) {
	// declaring the navigate for navigating the path
	const navigate = useNavigate();

	// making the manage account variable for making the popup
	const [showingPopup, setShowingPopup] = useState(false);

	// declaring the function onclick manage account for showing the popup
	const onClickShowingPopup = (event) => {
		// checking the manage account for showing the manage account popup
		if (showingPopup === false) {
			setShowingPopup(true);
			navigate("/home/manage");
		} else {
			setShowingPopup(false);
			window.history.back();
		}
	};
	// declaring token variable for getting global authToken
	const token = Cookies.get("authToken");

	// declaring user for user details from token decode
	const user = jwt_decode(token);

	// declaring dispatch that was made for redirecting
	const { dispatch } = useAuthContext();

	// made removeauth function for directing into login page
	const removeAuth = async () => {
		Cookies.remove("authToken");
		// changed the dispatch into the logout from the login
		dispatch({ type: "LOGOUT" });
		localStorage.removeItem("authToken");
		// navigating into the login screen after logout
		navigate("/login");
	};
	return (
		<div className='ManageAccountWrapper'>
			<div className='ManageAccountLogoEmailWrapper'>
				<img
					src={
						props.userProfileDirectPhoto === "NULL" ||
						props.userProfileDirectPhoto === null
							? userProfileLogo
							: CONFIG.STUDENTURL + props.userProfileDirectPhoto
					}
					alt='userProfileLogo'
				/>
				<div className='ManageAccountNameEmailWrapper'>
					<p>
						{/* directly using decoded username because internet will not make issue */}
						{user.firstName} {user.lastName}
					</p>
					<p>{user.email}</p>
				</div>
			</div>
			<hr />
			<div className='ManageAccountButtonWrapper'>
				<button
					onClick={() => {
						onClickShowingPopup();
					}}>
					Manage Account
				</button>
				{/* added the remove auth function for signing out users */}
				<button onClick={removeAuth}>Sign Out</button>
			</div>
		</div>
	);
}
