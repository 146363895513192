import axios from "axios";
import { CONFIG } from "../../config";
export const getCompletedLessonIDs = async (coursesDetails) => {
	try {
		const response = await axios.get(
			`${CONFIG.COURSEURL}/api/student-progress/find-completed-video-ids/${coursesDetails.studentId}/${coursesDetails.courseId}/${coursesDetails.subCourseId}`
		);
		return response.data;
	} catch (error) {
		// returning blank array after getting server and internet error
		return [];
	}
};
