import axios from "axios";
import { CONFIG } from "../../config";
export const downloadBookURLService = async (courseId, subcourseId) => {
	try {
		const response = await axios.get(
			`${CONFIG.COURSEURL}/api/books/get-requiredbook/` +
				courseId +
				`/` +
				subcourseId
		);
		return response.data;
	} catch (error) {
		// returning blank array after getting server and internet error
		return [];
	}
};
