import React, { useState, useEffect } from "react";

// importing the course screen styles for stylings
import "./../styles/CoursesScreen.css";

// importing course cards components for showing courses
import SubCoursesCards from "./components/SubCoursesCards";
// import Cookies from "js-cookie";

// importing the courses list from course services
import { getSubCoursesService } from "../../../../services/courses/GetSubCoursesService";
import { useLocation } from "react-router-dom";
import { getSubCoursesWithSubCourseId } from "../../../../services/courses/GetSubCoursesWithSubCourseId";

export default function SubCoursesIndexScreen() {
  // used progress and completed variable for toggling buttons
  const [progress, setProgress] = useState(true);
  const [completed, setCompleted] = useState(false);
  const location = useLocation();

  // used response for getting response from courseservice
  const [response, setResponse] = useState([]);
  useEffect(() => {
    if (
      location.state === null
        ? null
        : location.state.useGetSubCoursesWithSubCourseId === true
    ) {
      getSubCoursesWithSubCourseId(
        location.state === null ? null : location.state.subCoursesId
      ).then(setResponse);
    } else if (
      location.state === null
        ? null
        : location.state.useGetSubCoursesWithSubCourseId === false
    ) {
      getSubCoursesService(
        location.state === null ? null : location.state.coursesId
      ).then(setResponse);
    }
  }, [location.state]);

  return (
    <div className="HomeContextContainer">
      <h2 className="CoursesScreenHeading">Enrolled Courses</h2>
      {/* making statusbuttons for viewing completed and inprogress courses */}
      <div className="CoursesScreenStatusButtons">
        <button
          className={progress === true ? "Switched" : "CoursesScreenButton"}
          onClick={() => {
            setProgress(true);
            setCompleted(false);
          }}
        >
          In Progress
        </button>
        <button
          className={completed === true ? "Switched" : "CoursesScreenButton"}
          onClick={() => {
            setProgress(false);
            setCompleted(true);
          }}
        >
          Completed
        </button>
      </div>
      <div className="CoursesScreenCoursesList">
        {completed ? null :
					response.map((responses) => {
          return (
            <SubCoursesCards
              key={responses.id}
              subCourseSerialNumber={responses.id}
              title={responses.subcourseTitle}
              thumbnail={responses.imgURL}
              subcourseDescription={responses.subcourseDescription}
              subCourseId={responses.subCourseId}
              isModule={responses.isModule}
            />
          );
        })}
      </div>
    </div>
  );
}
