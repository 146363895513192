// importing the authentication css for global stylings
import "../styles/GlobalAuth.css";

// importing the page not found css which are not common
import "./styles/PageNotFound.css";

// importing the components for getting input fields and buttons
import LeftComponent from "./components/LeftComponent";

// importing the auth container for wrapping out contexts
import AuthContainer from "../../global/AuthContainer";

import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <AuthContainer>
      <LeftComponent />
      {/* below division wraps all the login field */}
      <div className="RightComponentLoginFields">
        {/* below division wraps the headings */}
        <div className="RightComponentHeading">
          <h1>Opps!</h1>
          <p className="AuthContainerBold">Page Not Found</p>
        </div>
        <div className="RightComponentNotFoundWrapper">
          <div className="RightComponentNotFoundPara">
            <p>
              Sorry, the page you're looking for doesn't exist. If you think
              something is broken, report a problem.
            </p>
          </div>
          {/* below division contains the login button */}
          <div className="RightComponentNotFoundButton">
            <button onClick={() => navigate("/home")}>RETURN HOME</button>
            {/* <button>RETURN HOME</button> */}
          </div>
        </div>
      </div>
    </AuthContainer>
  );
}
