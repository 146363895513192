import axios from "axios";
import { CONFIG } from "../../config";
export const GetLessonDetailsWithCourseId = async (courseId) => {
	try {
		const response = await axios.get(
			`${CONFIG.COURSEURL}/api/videos/video/course/all/` + courseId
		);
		return response.data;
	} catch (error) {
		// returning blank array after getting server and internet error
		return [];
	}
};
