import { useState, useEffect } from "react";
// importing lesson card thumbnail for showing inside the lesson cards
// import lessonCardsThumbnails from "../../../../assets/LessonsCardsThumbnails.webp";
import { downloadModuleURLService } from "../../../../services/lessons/DownloadModuleURLService";
import { usePopupContext } from "../../../../hooks/UsePopupContext";
// import { usePlayerContext } from "../../../../hooks/UsePlayerContext";
import sideCloseIcon from "../../../../assets/SideCloseIcon.svg";
import parse from "html-react-parser";

// importing link from react router for routing between screens
import { Link, useNavigate } from "react-router-dom";

// importing the cookies library
import Cookies from "js-cookie";

// importing the token decode library for decoding the global tokens
import decode from "jwt-decode";

// importing the lesson popup player from components
import LessonPlayerPopup from "./LessonPlayerPopup";

// importing the quizpopup for showing quiz
// import QuizPopup from "./QuizPopup";

export default function LessonsCards(props) {
	const { dispatch3 } = usePopupContext();
	// const { dispatch4 } = usePlayerContext();
	// const { popup } = usePopupContext();
	const [lessonPlayerModal, setLessonPlayerModal] = useState(false);
	const [audioEvaluationModal, setAudioEvaluationModal] = useState(false);
	const [realtimeDisclaimerModal, setRealtimeDisclaimerModal] =
		useState(false);

	// declaring response values for updating this when showmodal is clicked
	const [responseImage, setResponseImage] = useState("");
	const [responsePiece, setResponsePiece] = useState("");
	const [downloadModuleURL, setDownloadModuleURL] = useState("");
	const [studentID, setStudentID] = useState();
	const [courseLastLessonID, setCourseLastLessonID] = useState();
	const [courseId, setCourseId] = useState();
	const [subCoursesId, setSubCoursesId] = useState();

	const navigate = useNavigate();
	const removePrefixAndSuffix = (string) => {
		const prefix = props.courseId.toString().length;
		const suffix = props.subCoursesId.toString().length;
		return string.slice(prefix).slice(0, -suffix);
	};
	useEffect(() => {
		downloadModuleURLService(props.serialNumber).then(setDownloadModuleURL);
		const keyDownHandler = (event) => {
			if (event.key === "Escape") {
				setLessonPlayerModal(false);
				setAudioEvaluationModal(false);
				setRealtimeDisclaimerModal(false);
			}
		};
		document.addEventListener("keydown", keyDownHandler);
		return () => document.addEventListener("keydown", keyDownHandler);
	}, [props.serialNumber]);
	function evaluationClickedFunction() {
		// setAudioEvaluationModal(!audioEvaluationModal);
		setResponseImage(props.thumbnail);
		setResponsePiece(props.serialNumber);
		setStudentID(props.studentID);
		setCourseLastLessonID(props.courseLastLessonID);
		setCourseId(props.courseId);
		setSubCoursesId(props.subCoursesId);
		setRealtimeDisclaimerModal(!realtimeDisclaimerModal);
	}
	function replaceDocumentScreen() {
		const documentName = removePrefixAndSuffix(props.title);
		Cookies.set("documentName", documentName);
		Cookies.set("documentVideoID", props.serialNumber);
		Cookies.set("documentCourseId", props.courseId);
		Cookies.set("documentSubCoursesId", props.subCoursesId);
		window.location.replace("/home/document");
	}
	return (
		<div
			className={
				(
					decode(Cookies.get("authToken")).email ===
						"aubrey@lorrainemusicacademy.com" ||
					decode(Cookies.get("authToken")).email ===
						"lorrainemusicacademy001@gmail.com" ||
					decode(Cookies.get("authToken")).email ===
						"anastasia72@gmail.com" ||
					props.subCoursesId.toString() === "26" ||
					props.subCoursesId.toString() === "21" ||
					props.subCoursesId.toString() === "22" ||
					props.subCoursesId.toString() === "23" ||
					props.subCoursesId.toString() === "24" ||
					props.subCoursesId.toString() === "25" ||
					props.subCoursesId.toString() === "36" ||
					props.subCoursesId.toString() === "37" ||
					props.subCoursesId.toString() === "38"
						? // below line removes opacity for come india sing
						  // || props.courseId.toString() === "34"
						  true
						: props.isVideoCompleted
				)
					? "LessonsCardsButtonThumbnailWrapper"
					: "LessonsCardsButtonThumbnailBlocked"
			}>
			<div className='LessonCardsThumbnailsDivision'>
				<img src={props.thumbnail} alt='lessonCardsThumbnails' />
			</div>
			<div className='LessonCardsButtonWrapper'>
				{props.maestroDetails === undefined ? null : (
					<p style={{ textAlign: "left" }}>
						{parse(props.maestroDetails)}
					</p>
				)}
				{props.maestroDetails === undefined ? (
					<p>{removePrefixAndSuffix(props.title)}</p>
				) : null}
				{props.isDocument ? null : (
					<Link
						onClick={() => {
							(
								decode(Cookies.get("authToken")).email ===
									"aubrey@lorrainemusicacademy.com" ||
								decode(Cookies.get("authToken")).email ===
									"lorrainemusicacademy001@gmail.com" ||
								decode(Cookies.get("authToken")).email ===
									"anastasia72@gmail.com" ||
								props.subCoursesId.toString() === "26" ||
								props.subCoursesId.toString() === "21" ||
								props.subCoursesId.toString() === "22" ||
								props.subCoursesId.toString() === "23" ||
								props.subCoursesId.toString() === "24" ||
								props.subCoursesId.toString() === "25" ||
								props.subCoursesId.toString() === "36" ||
								props.subCoursesId.toString() === "37" ||
								props.subCoursesId.toString() === "38"
									? // below line unlocks video only for come india sing
									  // || props.courseId.toString() === "34"
									  true
									: props.isVideoCompleted
							)
								? //   dispatch4({
								  // 		type: "PLAYERTRUE",
								  // 		payload: props.title,
								  //   })
								  // use above code for optimizing popup
								  setLessonPlayerModal(!lessonPlayerModal)
								: console.log(
										"complete the evaluation for unlocking"
								  );
						}}>
						<p className='LessonCardsPlayButton'>Play Video</p>
					</Link>
				)}
				{props.isDocument ? (
					<Link
						onClick={() => {
							(
								decode(Cookies.get("authToken")).email ===
									"aubrey@lorrainemusicacademy.com" ||
								decode(Cookies.get("authToken")).email ===
									"lorrainemusicacademy001@gmail.com" ||
								decode(Cookies.get("authToken")).email ===
									"anastasia72@gmail.com" ||
								props.subCoursesId.toString() === "26" ||
								props.subCoursesId.toString() === "21" ||
								props.subCoursesId.toString() === "22" ||
								props.subCoursesId.toString() === "23" ||
								props.subCoursesId.toString() === "24" ||
								props.subCoursesId.toString() === "25" ||
								props.subCoursesId.toString() === "36" ||
								props.subCoursesId.toString() === "37" ||
								props.subCoursesId.toString() === "38"
									? true
									: props.isVideoCompleted
							)
								? replaceDocumentScreen()
								: console.log(
										"complete the evaluation for unlocking"
								  );
						}}>
						<p className='LessonCardsPlayButton'>Document</p>
					</Link>
				) : null}
				{props.courseId === "34" ? (
					<Link
						onClick={function () {
							(
								decode(Cookies.get("authToken")).email ===
									"aubrey@lorrainemusicacademy.com" ||
								decode(Cookies.get("authToken")).email ===
									"lorrainemusicacademy001@gmail.com" ||
								decode(Cookies.get("authToken")).email ===
									"anastasia72@gmail.com" ||
								props.subCoursesId.toString() === "21" ||
								props.subCoursesId.toString() === "22" ||
								props.subCoursesId.toString() === "23" ||
								props.subCoursesId.toString() === "24" ||
								props.subCoursesId.toString() === "25" ||
								props.subCoursesId.toString() === "36" ||
								props.subCoursesId.toString() === "37" ||
								props.subCoursesId.toString() === "38"
									? true
									: props.isVideoCompleted
							)
								? dispatch3({
										type: "POPUPTRUE",
										payload: props.serialNumber,
								  })
								: console.log(
										"complete the evaluation for unlocking"
								  );
						}}>
						<p className='LessonCardsPlayButton'>Play Quiz</p>
					</Link>
				) : null}
				{(
					props.subCoursesId.toString() === "26"
						? false
						: props.isevaluation
				) ? (
					<div>
						{
							<Link
								onClick={() => {
									(
										decode(Cookies.get("authToken"))
											.email ===
											"aubrey@lorrainemusicacademy.com" ||
										decode(Cookies.get("authToken"))
											.email ===
											"lorrainemusicacademy001@gmail.com" ||
										decode(Cookies.get("authToken"))
											.email ===
											"anastasia72@gmail.com" ||
										props.subCoursesId.toString() ===
											"21" ||
										props.subCoursesId.toString() ===
											"22" ||
										props.subCoursesId.toString() ===
											"23" ||
										props.subCoursesId.toString() ===
											"24" ||
										props.subCoursesId.toString() ===
											"25" ||
										props.subCoursesId.toString() ===
											"36" ||
										props.subCoursesId.toString() ===
											"37" ||
										props.subCoursesId.toString() === "38"
											? true
											: props.isVideoCompleted
									)
										? evaluationClickedFunction()
										: console.log(
												"complete the evaluation for unlocking"
										  );
								}}>
								<p className='LessonCardsPlayButton'>
									Evaluation
								</p>
							</Link>
						}
						{/* removing the downloadpdf button from the janaganamana */}
						{props.courseId === "2" ||
						props.courseId === "34" ? null : (
							<Link
								to={
									(
										decode(Cookies.get("authToken"))
											.email ===
											"aubrey@lorrainemusicacademy.com" ||
										decode(Cookies.get("authToken"))
											.email ===
											"lorrainemusicacademy001@gmail.com" ||
										decode(Cookies.get("authToken"))
											.email ===
											"anastasia72@gmail.com" ||
										props.subCoursesId.toString() ===
											"21" ||
										props.subCoursesId.toString() ===
											"22" ||
										props.subCoursesId.toString() ===
											"23" ||
										props.subCoursesId.toString() ===
											"24" ||
										props.subCoursesId.toString() ===
											"25" ||
										props.subCoursesId.toString() ===
											"36" ||
										props.subCoursesId.toString() ===
											"37" ||
										props.subCoursesId.toString() === "38"
											? true
											: props.isVideoCompleted
									)
										? downloadModuleURL.moduleurl
										: null
								}
								target={
									(
										decode(Cookies.get("authToken"))
											.email ===
											"aubrey@lorrainemusicacademy.com" ||
										decode(Cookies.get("authToken"))
											.email ===
											"lorrainemusicacademy001@gmail.com" ||
										decode(Cookies.get("authToken"))
											.email ===
											"anastasia72@gmail.com" ||
										props.subCoursesId.toString() ===
											"21" ||
										props.subCoursesId.toString() ===
											"22" ||
										props.subCoursesId.toString() ===
											"23" ||
										props.subCoursesId.toString() ===
											"24" ||
										props.subCoursesId.toString() ===
											"25" ||
										props.subCoursesId.toString() ===
											"36" ||
										props.subCoursesId.toString() ===
											"37" ||
										props.subCoursesId.toString() === "38"
											? true
											: props.isVideoCompleted
									)
										? "_blank"
										: null
								}>
								<p className='LessonCardsPlayButton'>
									Download PDF
								</p>
							</Link>
						)}
					</div>
				) : null}
				{lessonPlayerModal ? (
					<div className='LessonPlayerPopupWrapper'>
						<img
							src={sideCloseIcon}
							alt='sideCloseIcon'
							onClick={() =>
								setLessonPlayerModal(!lessonPlayerModal)
							}
						/>
						<LessonPlayerPopup videosName={props.title} />
					</div>
				) : null}
				{audioEvaluationModal ? (
					<div className='LessonPlayerPopupWrapper'>
						<img
							src={sideCloseIcon}
							alt='sideCloseIcon'
							onClick={() =>
								setAudioEvaluationModal(!audioEvaluationModal)
							}
						/>
						<div className='LessonPlayerPopupCard'>
							<h3>Evaluation</h3>
							<p>
								Yay!, You can choose from Real-Time & MP3
								Evaluation
							</p>
							<button
								onClick={() =>
									navigate("/home/evaluation", {
										state: {
											pieceId: responsePiece,
											thumbnail: responseImage,
											studentID: studentID,
											courseLastLessonID:
												courseLastLessonID,
											courseId: courseId,
											subCoursesId: subCoursesId,
										},
									})
								}>
								Mp3 Evaluation
							</button>
							<button
								onClick={() => {
									setAudioEvaluationModal(
										!audioEvaluationModal
									);
									setRealtimeDisclaimerModal(
										!realtimeDisclaimerModal
									);
								}}>
								Realtime
							</button>
						</div>
					</div>
				) : null}
				{realtimeDisclaimerModal ? (
					<div className='LessonPlayerPopupWrapper'>
						<img
							src={sideCloseIcon}
							alt='sideCloseIcon'
							onClick={() =>
								setRealtimeDisclaimerModal(
									!realtimeDisclaimerModal
								)
							}
						/>
						<div className='LessonPlayerPopupCard'>
							<h3>
								Note for Lorraine Music Academy Real-time Sound
								Recognition System
							</h3>
							<p>[Experiment]</p>
							<p>
								Welcome to our Real-time Sound Recognition
								System. Before you begin using our services,
								please take a moment to read and understand the
								following important points:
							</p>
							<ul>
								<li>
									<strong>Quiet Environment:</strong> To
									achieve the best results, ensure that the
									room in which you use our Real-time Sound
									Recognition System is quiet. External noises
									such as fans, air conditioners, traffic,
									kitchen sounds, conversations in the
									background, and other distracting noises may
									affect the accuracy of the system.
								</li>
								<li>
									<strong>Mobile Phones:</strong> We recommend
									putting your mobile phone on airplane mode
									or ensuring it is in silent mode to minimize
									interference during sound recognition.
								</li>
								<li>
									<strong>Device Placement:</strong> For
									optimal results, place your device near the
									application or microphone to improve sound
									capture.
								</li>
								<li>
									<strong>Accuracy Disclaimer:</strong> Please
									be aware that the results you obtain from
									our Real-time Sound Recognition System may
									not be 100% accurate.
								</li>
							</ul>
							<p>
								We are continuously working to enhance and
								improve the system's accuracy and robustness for
								your benefit.
							</p>
							<p>
								By using our Real-time Sound Recognition System,
								you acknowledge understanding and agree to the
								above conditions and disclaimers. We are
								committed to providing you with the best
								possible experience and results, and we
								appreciate your understanding as we strive to
								make our system even better.
							</p>
							<p>
								If you have any questions or concerns, please
								don't hesitate to contact us via{" "}
								<strong>Email</strong>{" "}
								<em>feedback@lorrainemusicacademy.com</em> or{" "}
								<strong>Call / WhatsApp</strong> @ +91
								9711805049.
							</p>
							<p>
								Thank you for choosing our Real-time Sound
								Recognition System.
							</p>
							<button
								onClick={() => {
									// setAudioEvaluationModal(
									// 	!audioEvaluationModal
									// );
									setRealtimeDisclaimerModal(
										!realtimeDisclaimerModal
									);
								}}>
								Cancel
							</button>
							<button
								onClick={() =>
									navigate("/home/realtime", {
										state: {
											pieceId: responsePiece,
											thumbnail: responseImage,
											studentID: studentID,
											courseLastLessonID:
												courseLastLessonID,
											courseId: courseId,
											subCoursesId: subCoursesId,
										},
									})
								}>
								Accept
							</button>
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
}
