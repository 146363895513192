import Cookies from "js-cookie";
import { createContext, useReducer } from "react";

export const AuthContext = createContext();
export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { user: action.payload };
    case "LOGOUT":
      return { user: null };
    default:
      return state;
  }
};

const currentTime = new Date().getTime();
function authLocalStorageToken() {
  const expirationTime = localStorage.getItem("expirationTime");
  console.log(expirationTime);
  console.log(currentTime);
  if (currentTime > expirationTime) {
    localStorage.removeItem("authToken");
    return null;
  } else {
    Cookies.set("authToken", localStorage.getItem("authToken"));
    return localStorage.getItem("authToken");
  }
}
// console.log(localStorage.getItem("authToken"));
// console.log(Cookies.get("authToken"));
// var authLocalStorageTokens = authLocalStorageToken();
var token = Cookies.get("authToken");
var value = null;
if (
  (token === "null" || token === "undefined" || token === undefined) &&
  authLocalStorageToken() === null
) {
  value = authLocalStorageToken();
} else if (
  (token !== "null" || token !== "undefined") &&
  authLocalStorageToken() === null
) {
  // console.log(token);
  Cookies.set("authToken", token);
  value = Cookies.get("authToken");
} else if (token === "null" && authLocalStorageToken() !== null) {
  value = authLocalStorageToken();
} else if (token !== "null" && authLocalStorageToken() !== null) {
  value = authLocalStorageToken();
}
// if (token === "") {
//   value = null;
// } else {
//   value = token;}
export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: value,
  });
  // console.log("authContext state:", state);
  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
