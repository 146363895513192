import axios from "axios";
import { CONFIG } from "../../config";
export const passwordRecovery = async (rcoveryData) => {
	try {
		await axios.post(
			`${CONFIG.AUTHURL}/api/auth/change-password`,
			// `http://localhost:8080/api/auth/change-password`,
			rcoveryData
		);
		return "password successfully changed";
	} catch (error) {
		return "password not changed";
	}
};
