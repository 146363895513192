// importing the use state for updating local variables
import { useState, useEffect, useRef } from "react";

// importing the sidebar css for the sidebar stylings
import "./styles/Sidebar.css";

// importing sidebar datas for the side bar menu component
import { SideBarData } from "../../data/Items";

// importing the link from react router dom for routing
import { Link } from "react-router-dom";

// importing the logout icon for the logout component
import logoutIcon from "../../../../../assets/violetIcons/SidebarLogOutLine.svg";

// importing the blue logout icon for the logout component
import blueLogoutIcon from "../../../../../assets/blueIcons/SidebarLogOutIcon.svg";

// importing the white logout icon for the mobile logout component
import logoutWhiteIcon from "../../../../../assets/whiteIcons/SidebarLogOutLine.svg";

// importing the logo icon for keeping inside sidebar
import logo from "../../../../../assets/BlackLogo.png";

// importing close icon that we use for portrait mobile screen
import sideCloseIcon from "../../../../../assets/SideCloseIcon.svg";

// importing menu icon that we use for portrait mobile screen
import sideMenuIcon from "../../../../../assets/SideMenuIcon.svg";

// importing the jscookie for getting the cookie values
import Cookies from "js-cookie";

// importing the jwt decode for decoding the tokens details
import jwt_decode from "jwt-decode";

// importing use auth context for resolving redirecting issue
import { useAuthContext } from "../../../../../hooks/UseAuthContext";

export default function Sidebar() {
  // declaring token variable for getting global authToken
  const token = Cookies.get("authToken");

  // declaring user for user details from token decode
  const user = jwt_decode(token);

  // used selected variable for selecting the menu elements
  const [selected, setSelected] = useState("/home");

  // used hovered variable for hovering the menu elements
  const [onHover, setOnHover] = useState();

  // button toggle for toggling menu bar for portrat screen
  const [buttonToggle, setButtonToggle] = useState(false);

  // declaring dispatch that was made for redirecting
  const { dispatch } = useAuthContext();

  // made removeauth function for directing into login page
  const removeAuth = async () => {
    Cookies.remove("authToken");
    localStorage.removeItem("authToken");
    // changed the dispatch into the logout from the login
    dispatch({ type: "LOGOUT" });
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleOutsideClick(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setButtonToggle(false);
        }
      }
      document.addEventListener("click", handleOutsideClick);
      return () => document.removeEventListener("click", handleOutsideClick);
    }, [ref]);
  }
  const box = useRef(null);
  useOutsideAlerter(box);
  return (
    <div className="SidebarWrapper">
      <Link to="/home" className="SidebarLogoWrapper">
        {/* added link into logo for routing into home page */}
        <img src={logo} alt="logo" />
      </Link>
      {/* below division was added only for the portrait screen */}
      <div
        className={
          buttonToggle ? "SidebarDropdownMenu Pressed" : "SidebarDropdownMenu"
        }
      >
        {/* below button toggles dropdown menu by updating variable */}
        <button
          className="SidebarButtonDropDown"
          ref={box}
          onClick={() => {
            buttonToggle ? setButtonToggle(false) : setButtonToggle(true);
          }}
        >
          Menu
          <img
            src={buttonToggle ? sideCloseIcon : sideMenuIcon}
            alt="sideBarDataIcons"
          />
        </button>
        <ul className="SidebarDropdownContent">
          {user.coursetype === "async"
            ? SideBarData.filter((item, index) => index !== 3).map(
                (item, index) => {
                  return (
                    <Link to={item.to}>
                      {/* added links and list tags because both are mandatory */}
                      <li
                        className={selected === item.to ? " Active" : []}
                        key={index}
                        onClick={() => setSelected(item.to)}
                      >
                        <img
                          src={
                            selected === item.to
                              ? item.blueIcon
                              : item.whiteIcon
                          }
                          alt="sideBarDataIcons"
                          className="SideBarDataIcons"
                        />
                        {item.heading}
                      </li>
                    </Link>
                  );
                }
              )
            : SideBarData.map((item, index) => {
                return (
                  <Link to={item.to}>
                    {/* added links and list tags because both are mandatory */}
                    <li
                      className={selected === item.to ? " Active" : []}
                      key={index}
                      onClick={() => setSelected(item.to)}
                    >
                      <img
                        src={
                          selected === item.to ? item.blueIcon : item.whiteIcon
                        }
                        alt="sideBarDataIcons"
                        className="SideBarDataIcons"
                      />
                      {item.heading}
                    </li>
                  </Link>
                );
              })}
          <Link to="/login" onClick={removeAuth}>
            <li className={[]}>
              <img
                src={logoutWhiteIcon}
                alt="sideBarDataIcons"
                className="SideBarDataIcons"
              />
              Logout
            </li>
          </Link>
        </ul>
      </div>
      {/* below division was added for the laptop and landscape screens */}
      <ul className="SidebarMenuComponent">
        {user.coursetype === "async"
          ? SideBarData.filter((item, index) => index !== 3).map(
              (item, index) => {
                return (
                  <Link to={item.to}>
                    <li
                      className={
                        selected === item.to || onHover === item.to
                          ? "Active"
                          : []
                      }
                      key={index}
                      onClick={() => setSelected(item.to)}
                      onMouseOver={() => setOnHover(item.to)}
                      onMouseOut={() => setOnHover()}
                    >
                      <img
                        src={
                          selected === item.to || onHover === item.to
                            ? item.blueIcon
                            : item.violetIcon
                        }
                        alt="sideBarDataIcons"
                        className="SideBarDataIcons"
                      />
                      {item.heading}
                    </li>
                  </Link>
                );
              }
            )
          : SideBarData.map((item, index) => {
              return (
                <Link to={item.to}>
                  <li
                    className={
                      selected === item.to || onHover === item.to
                        ? "Active"
                        : []
                    }
                    key={index}
                    onClick={() => setSelected(item.to)}
                    onMouseOver={() => setOnHover(item.to)}
                    onMouseOut={() => setOnHover()}
                  >
                    <img
                      src={
                        selected === item.to || onHover === item.to
                          ? item.blueIcon
                          : item.violetIcon
                      }
                      alt="sideBarDataIcons"
                      className="SideBarDataIcons"
                    />
                    {item.heading}
                  </li>
                </Link>
              );
            })}
        <Link to="/login" onClick={removeAuth}>
          <li
            className={
              selected === "/login" || onHover === "/login"
                ? "MenuItemsLogout Active"
                : "MenuItemsLogout"
            }
            onClick={() => setSelected("/login")}
            onMouseOver={() => setOnHover("/login")}
            onMouseOut={() => setOnHover()}
          >
            <img
              src={
                selected === "/login" || onHover === "/login"
                  ? blueLogoutIcon
                  : logoutIcon
              }
              alt="sideBarDataIcons"
              className="SideBarDataIcons"
            />
            Logout
          </li>
        </Link>
      </ul>
    </div>
  );
}
