import React, { useState, useEffect } from "react";
// importing the lessons screen styles for screen stylings
import "./styles/LessonsScreen.css";

// importing the backspace arrow from the assets folder
import backspaceArrow from "../../../assets/BackspaceArrow.svg";
// import redBookWebCover from "../../../assets/RedBookWebCover.jpg";

// importing the youtube icon for showing inside course dashboard
import profileYoutubeIcon from "../../../assets/profileIcons/ProfileYoutubeIcon.svg";

// importing the lesson cards for showing different lessons
import LessonsCards from "./components/LessonsCards";

// importing link from react router for routing between screens
// import { Link, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

// importing the lessons details services from the services folder
import { GetLessonDetailsWithCourseId } from "../../../services/lessons/GetLessonDetailsWithCourseId";
import { GetLessonDetailsWithSubCourseId } from "../../../services/lessons/GetLessonDetailsWithSubCourseId";
import { downloadBookURLService } from "../../../services/courses/DownloadBookURLService";
import { getCompletedLessonIDs } from "../../../services/dripFeeding/GetCompletedLessonIDs";
import { usePopupContext } from "../../../hooks/UsePopupContext";
// import { usePlayerContext } from "../../../hooks/UsePlayerContext";
import parse from "html-react-parser";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

// importing the quizpopup for showing quiz
import QuizPopup from "./components/QuizPopup";
// import LessonPlayerPopup from "./components/LessonPlayerPopup";

export default function LessonsScreen() {
	const { popup } = usePopupContext();
	// const { player } = usePlayerContext();
	// response keep the values that we get from videoservice
	const [response, setResponse] = useState([]);
	// const navigate = useNavigate();

	// declaring below variables for updating cookie
	const [coursesImage, setCoursesImage] = useState("");
	const [coursesName, setCoursesName] = useState("");
	const [courseDescription, setCourseDescription] = useState("");
	const [courseId, setCourseId] = useState(0);
	const [subCoursesId, setSubCoursesId] = useState(0);
	const [downloadBookURL, setDownloadBookURL] = useState("");
	const [completedVideosList, setCompletedVideosList] = useState([]);
	// declaring below variable for updating the specific user progress
	const [userLessonProgress, setUserLessonProgress] = useState();
	function removeDuplicates(arr) {
		return [...new Set(arr)];
	}

	useEffect(() => {
		if (Cookies.get("useGetLessonDetailsWithSubCoursesId") === "false") {
			GetLessonDetailsWithCourseId(Cookies.get("coursesId")).then(
				setResponse
			);
			setCourseId(Cookies.get("coursesId"));
			setSubCoursesId(0);
			downloadBookURLService(Cookies.get("coursesId"), 0).then(
				setDownloadBookURL
			);
			// below code was made for getting the completed video array
			const decode = jwt_decode(Cookies.get("authToken"));
			let coursesDetails = {
				studentId: decode.id,
				courseId: parseInt(Cookies.get("coursesId")),
				subCourseId: 0,
			};
			getCompletedLessonIDs(coursesDetails).then((completedLessons) => {
				// updating the below variable only for blocking the videos
				setCompletedVideosList(completedLessons);
				// include new subcourseid when videos are manually unblocked
				if (Cookies.get("coursesId") === "3") {
					// updating the below variable for showing the updated user progress
					setUserLessonProgress(
						removeDuplicates(completedLessons).length + 2
					);
				} else if (Cookies.get("coursesId") === "2") {
					// unblocking the 3videos for the jgm course
					setUserLessonProgress(
						removeDuplicates(completedLessons).length + 3
					);
				} else if (Cookies.get("coursesId") === "34") {
					// unblocking the 5videos for come india sing
					setUserLessonProgress(
						removeDuplicates(completedLessons).length + 5
					);
				} else {
					// updating the below variable for showing the updated user progress
					setUserLessonProgress(
						removeDuplicates(completedLessons).length
					);
				}
			});
		} else if (
			Cookies.get("useGetLessonDetailsWithSubCoursesId") === "true"
		) {
			console.log(Cookies.get("subCoursesId"));
			GetLessonDetailsWithSubCourseId(Cookies.get("subCoursesId")).then(
				setResponse
			);
			setCourseId(0);
			setSubCoursesId(Cookies.get("subCoursesId"));
			downloadBookURLService(0, Cookies.get("subCoursesId")).then(
				setDownloadBookURL
			);
			// below code was made for getting the completed video array
			const decode = jwt_decode(Cookies.get("authToken"));
			let coursesDetails = {
				studentId: decode.id,
				courseId: 0,
				subCourseId: parseInt(Cookies.get("subCoursesId")),
			};
			getCompletedLessonIDs(coursesDetails).then((completedLessons) => {
				// updating the below variable only for blocking the videos
				setCompletedVideosList(completedLessons);
				if (
					// include new subcourseid when videos are manually unblocked
					Cookies.get("subCoursesId") === "1" ||
					Cookies.get("subCoursesId") === "5" ||
					Cookies.get("subCoursesId") === "13" ||
					Cookies.get("subCoursesId") === "18" ||
					Cookies.get("subCoursesId") === "26" ||
					Cookies.get("subCoursesId") === "29"
					// unblocking the 2videos for the ff2 course
					// unblocking the 2videos for the ff1 course
					// unblocking the 2videos for the walking together
				) {
					// updating the below variable for showing the updated user progress
					setUserLessonProgress(
						removeDuplicates(completedLessons).length + 2
					);
				} else if (
					Cookies.get("subCoursesId") === "12" ||
					Cookies.get("subCoursesId") === "17"
				) {
					// unblocking the 4videos for the ff1 course
					setUserLessonProgress(4);
				} else {
					// updating the below variable for showing the updated user progress
					setUserLessonProgress(
						removeDuplicates(completedLessons).length
					);
				}
			});
		}
		setCoursesName(Cookies.get("courseTitle"));
		setCourseDescription(Cookies.get("courseDescription"));
		setCoursesImage(Cookies.get("courseImage"));
	}, []);
	var progress1Per = (userLessonProgress * 100) / response.length + "%";
	const decode = jwt_decode(Cookies.get("authToken"));
	// var progress2Per = 100 - (userLessonProgress * 100) / response.length + "%";
	return (
		<div className='HomeContextContainer'>
			<div className='LessonsCreenDescThumbnailWrapper'>
				<div className='LessonsScreenWholeDescription'>
					<div className='LessonsScreenHeading'>
						<Link to='/home/courses'>
							<img src={backspaceArrow} alt='backspaceArrow' />
						</Link>
						<h2>{coursesName}</h2>
					</div>
					<div className='LessonsScreenVideosCount'>
						<img
							src={profileYoutubeIcon}
							alt='profileYoutubeIcon'
						/>
						<p>{response.length} Lessons</p>
					</div>
					<p>{parse("<span>" + courseDescription + "</span>")}</p>
					<Link
						to={downloadBookURL.bookurl}
						target='_blank'
						className='LessonsScreenDownloadBook'>
						Download Book
					</Link>
				</div>
				<div className='LessonsScreenThumbnailSpace'>
					<img src={coursesImage} alt='redBookWebCover' />
				</div>
			</div>
			{response.length === 0 ||
			userLessonProgress === undefined ? null : (
				<div>
					<div className='LessonsScreenCourseProgress'>
						<p>Course Progress</p>
						<p>
							{userLessonProgress + " / " + response.length}{" "}
							Lessons
						</p>
					</div>
					<div className='LessonsScreenProgressBars'>
						<hr
							className='LessonsScreenProgressBar1'
							style={{ width: progress1Per }}
						/>
					</div>
				</div>
			)}
			{popup === null || popup === undefined ? null : (
				<div className='LessonPlayerPopupWrapper'>
					<QuizPopup
						videoID={popup}
						isDocument={false}
						courseId={courseId}
						subCoursesId={subCoursesId}
					/>
				</div>
			)}
			{/* {player === null || player === undefined ? null : (
				<div className='LessonPlayerPopupWrapper'>
					<LessonPlayerPopup videosName={player} />
				</div>
			)} */}
			{courseId === "34" ? (
				<div className='LessonsScreenLessonsHeading'>
					<h2>Maestros Performing</h2>
					<div className='LessonsScreenCardsWrapper'>
						<LessonsCards
							key={0}
							serialNumber={0}
							isVideoCompleted={true}
							studentID={decode.id}
							courseLastLessonID={0}
							maestroDetails={
								"Bobby Cash<br/><span style='font-weight:400'>India</span><br/><span style='font-weight:400'>Guitar & Singing</span>"
							}
							title={"Bobby-Cash-Performance-JGM.mp4"}
							thumbnail={
								"https://cdn.lorrainemusicacademy.org/images/Guitar.jpg"
							}
							isevaluation={false}
							courseId={""}
							subCoursesId={""}
						/>
						<LessonsCards
							key={0}
							serialNumber={0}
							isVideoCompleted={true}
							studentID={decode.id}
							courseLastLessonID={0}
							maestroDetails={
								"Dr. Marialena Fernandes<br/><span style='font-weight:400'>Austria</span><br/><span style='font-weight:400'>Piano</span>"
							}
							title={"Marialena-Fernandes-JGM.mp4"}
							thumbnail={
								"https://cdn.lorrainemusicacademy.org/images/Piano.jpg"
							}
							isevaluation={false}
							courseId={""}
							subCoursesId={""}
						/>
						<LessonsCards
							key={0}
							serialNumber={0}
							isVideoCompleted={true}
							studentID={decode.id}
							courseLastLessonID={0}
							maestroDetails={
								"Hidayat Hussain Khan<br/><span style='font-weight:400'>USA</span><br/><span style='font-weight:400'>Sitar</span>"
							}
							title={"Hidayat-Hussain-Khan-JGM.mp4"}
							thumbnail={
								"https://cdn.lorrainemusicacademy.org/images/Sitar.jpg"
							}
							isevaluation={false}
							courseId={""}
							subCoursesId={""}
						/>
						<LessonsCards
							key={0}
							serialNumber={0}
							isVideoCompleted={true}
							studentID={decode.id}
							courseLastLessonID={0}
							maestroDetails={
								"Deborah Gonsalves & Berthold Hamburger<br/><span style='font-weight:400'>Spain & Germany</span><br/><span style='font-weight:400'>Violin & Cello</span>"
							}
							title={"Deborah-Berthold-JGM.mp4"}
							thumbnail={
								"https://cdn.lorrainemusicacademy.org/images/Violin.jpg"
							}
							isevaluation={false}
							courseId={""}
							subCoursesId={""}
						/>
					</div>
				</div>
			) : null}
			<div className='LessonsScreenLessonsHeading'>
				<h2>Lessons</h2>
				<div className='LessonsScreenCardsWrapper'>
					{response.map((responses) => {
						const decode = jwt_decode(Cookies.get("authToken"));
						return (
							<LessonsCards
								key={responses.id}
								serialNumber={responses.id}
								isVideoCompleted={
									responses.id === 1 ||
									responses.id === 2 ||
									responses.id === 68 ||
									responses.id === 69 ||
									responses.id === 73 ||
									responses.id === 74 ||
									responses.id === 151 ||
									responses.id === 152 ||
									responses.id === 153 ||
									responses.id === 154 ||
									responses.id === 155 ||
									responses.id === 156 ||
									responses.id === 83 ||
									responses.id === 84 ||
									responses.id === 85 ||
									responses.id === 173 ||
									responses.id === 174 ||
									responses.id === 175 ||
									responses.id === 176 ||
									responses.id === 177 ||
									responses.id === 178 ||
									responses.id === 186 ||
									responses.id === 187 ||
									responses.id === 150 ||
									responses.id === 277 ||
									responses.id === 278 ||
									responses.id === 231 ||
									responses.id === 232 ||
									responses.id === 436 ||
									responses.id === 437 ||
									responses.id === 438 ||
									responses.id === 439 ||
									responses.id === 440 ||
									responses.id === 455 ||
									responses.id === 456 ||
									responses.id === 470 ||
									responses.id === 471 ||
									responses.id === 462 ||
									responses.id === 463
										? true
										: completedVideosList === undefined
										? [].includes(responses.id)
										: completedVideosList.includes(
												responses.id
										  )
								}
								studentID={decode.id}
								courseLastLessonID={
									response[response.length - 1].id
								}
								title={responses.videoTitle}
								thumbnail={responses.imgURL}
								isevaluation={responses.isevaluation}
								isDocument={responses.isDocument}
								courseId={courseId}
								subCoursesId={subCoursesId}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
}
